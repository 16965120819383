import { FilteredProjectApiModel } from "../../filteredApi/FilteredProjectApiModel";
import { IHttp } from "../../IHttp";
import { IProjectGroupPermissionsApi } from "./IProjectGroupPermissions.api";

export class ProjectGroupPermissionsApi
  extends FilteredProjectApiModel<FP.Entities.IPermissions>
  implements IProjectGroupPermissionsApi
{
  controller: string = "project-group-authorisation";
  urlWithOrg: string;
  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "project-group-authorisation");
    this.urlWithOrg = `${this.http.url}/${this.version}/organisations`;
  }
}
