import { FormFieldModel } from "../../formField/FormField_model";
import { ICheckboxSliderModel } from "./ICheckboxSliderModel";
import { Slider, SliderProps } from "../../../../components/ui/_forms/Slider";
import React from "react";
import { action, observable } from "mobx";
import I18n from "../../../localization/I18n";
import { SliderTypes } from "@flightpath/coreui/dist/ui/_forms/Slider";
import { ReactCheckbox } from "@flightpath/coreui/dist/ui/_forms/Checkbox";

export interface CheckboxSliderProps extends SliderProps { }

export class CheckboxSliderModel extends FormFieldModel<number, CheckboxSliderProps> implements ICheckboxSliderModel {
  checked: boolean;
  testId?: string;
  @observable value: number;
  @observable isUnknown: boolean;
  @observable oldValue: number;
  adviceText: (...args: any) => string;
  isDefaultValueUnknown: boolean;
  validate: (...args: any[]) => any;

  /**
   *
   */
  constructor(initOpts?: ICheckboxSliderModel) {
    super(initOpts);
    if (initOpts) {
      this.isUnknown = (initOpts.isDefaultValueUnknown && !initOpts.value && !initOpts.defaultValue) || initOpts.value === -1;
      this.value = initOpts.value || this.value || this.defaultValue;
      this.oldValue = this.defaultValue;
      this.adviceText = initOpts.adviceText;
      this.validate = initOpts.validate || (this.validate as any);
      this.defaultValue = initOpts.defaultValue ?? 0;
      this.testId = initOpts.testId || null;
    }
  }

  @action
  setValue = (val: number) => {
    this.oldValue = this.value <= 0 ? 5 : this.value;
    this.value = val;

    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  };

  @action
  setValueThroughCheckfield = (e: React.SyntheticEvent<any>) => {
    const val = e.currentTarget as HTMLInputElement;
    this.isUnknown = val.checked;
    this.setValue(this.getSliderValue(val.checked));
  };

  getSliderValue = (isChecked: boolean): number => {
    // If the value is not set, then use the default
    if (this.value === null || this.value === undefined) {
      return this.defaultValue;
    }

    // If the "Unknown" checkbox is not ticked but the value is unknown (-1) the set the default value
    if (isChecked === false && this.value === -1) {
      return this.defaultValue;
    }

    // Otherwise use the stored value
    return this.value;
  };

  setFieldValue = (e: React.SyntheticEvent<any>) => {
    const val = e.currentTarget as HTMLInputElement;
    this.setValue(val.checked ? -1 : this.value);
  };

  extractValue = () => {
    return this.isUnknown ? -1 : this.value;
  };

  reset(): void {
    this.isUnknown = true;
    this.value = null;
  }

  @action
  sliderChange = (ev, value) => {
    if (value <= 0) {
      this.sliderChange(ev, this.value)
    }
    this.setValue(value as number);
    this.isUnknown = value > 0 ? false : true;
  };

  renderComponent = () => {
    let props = {
      className: `form-control ${this.className || ""}`,
      id: this.key,
      onChange: this.setValueThroughCheckfield,
      checked: this.isUnknown,
      testId: this.testId
    };
    return (
      <>
        <div className="checkbox-slider" data-testid={props.testId}>
          <div className="d-flex" style={{ width: "100%" }}>
            <div style={{ width: "110px", height: "40px", display: "flex", alignItems: "center" }}>
              <ReactCheckbox color="#0c0c0c" checked={this.isUnknown} {...props} />
              {/* <Checkbox {...props} checked={this.isUnknown} className="mt-1 unknown-checkbox" /> */}
              <h4 className="mb-0 ml-1 mr-2 d-inline">{I18n.t("phrases.unknown")}</h4>
              <div className="ml-1 vertical-pipe"></div>
            </div>
            <Slider
              {...this.componentProps}
              className="ml-1"
              type={this.isUnknown ? SliderTypes.UNKNOWN : this.componentProps.type}
              value={this.value}
              style={{ width: "100%" }}
              // @ts-ignore
              onChange={this.sliderChange}
              isUnknownValue={this.isUnknown}
            />
          </div>

          {this.isUnknown && (
            <div className="unknown-note">
              <p className="mb-0">
                {I18n.t("forms.checkBoxSliderUnknownNote")}
              </p>
            </div>
          )}
          {!this.isUnknown && this.adviceText && (
            <div className="unknown-note">
              <p className="mb-0">
                {this.adviceText(this.value)}
              </p>
            </div>
          )}
        </div>
      </>
    );
  };
}
