import { Modal, ModalHeader, ModalContent, ModalFooter } from "../../components/ui/Modal";
import { IModalModel } from "./IModalModel";
import * as React from "react";
import { UiActionComponent } from "../uiAction/UiAction";
import { observer } from "mobx-react";
import { ButtonIcon, ButtonIconShapes, ButtonTypes } from "../../components/ui/Button";
import { IconSymbols } from "../../components/ui/Icon";
import { Hr } from "../../components/ui/Hr";

export interface ModalViewProps {
  model: IModalModel;
}

@observer
export class ModalView extends React.Component<ModalViewProps> {
  render() {
    const { model } = this.props;

    return (
      <Modal {...model.componentProps} wrapperAnimationClassName={model.animationClass} onClose={model.onClose} disableBackdrop={model.disableBackdrop}>
        {model.title || model.showClose ? (
          <ModalHeader>
            {model.title}
            {model.showClose ? (
              <ButtonIcon
                className="modal__close"
                onClick={model.onClose}
                type={ButtonTypes.OUTLINE_PRIMARY}
                shape={ButtonIconShapes.RECTANGLE}
                symbol={IconSymbols.Close}
              />
            ) : null}
          </ModalHeader>
        ) : null}

        {model.hasTitleSeparator &&
          <div className="row">
            <div className="col-12">
              <Hr className="mb-0 mt-0 p-0" />
            </div>
          </div>}

        {model.content ? <ModalContent>{model.content}</ModalContent> : null}

        {model.actions?.length ? (
          <ModalFooter className={`${model.className || ""} py-3 container-fluid`}>
            <div className="row">
              <div className="col">
                {model.actions.map(action => (
                  <UiActionComponent key={action.id} action={action} />
                ))}
              </div>
            </div>
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }
}
