import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../core/filter/Filter_model";
import { AppService } from "strikejs-app-service";
import { IUsersApi } from "../../../services/api/v1/users/IUsers.api";
import { Services } from "../../../constants";
import { observable, action } from "mobx";
import { Enums } from "../../../enums";

export default class NotificationsModel {
  @observable unreadNotifications: number = 0;
  @observable.ref latestNotifications: FP.Entities.INotification[] = [];
  @observable isDataLoaded: boolean = false;
  filterModel: IFilterModel<FP.Entities.INotification> | any;
  appService: AppService;
  userProvider: IUsersApi;
  organisationId: number;

  constructor(appService: AppService, organisationId: number) {
    this.appService = appService;
    this.userProvider = this.appService.getService<IUsersApi>(Services.UsersApi);

    this.setFilterModel();

    this.organisationId = organisationId;
  }

  setFilterModel = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions => await this.userProvider.getNotifications(this.organisationId, filterOptions)
      }
    };
    this.filterModel = new FilterModel(config);

    this.filterModel.setConfig({
      onDataLoaded: this.setLatestNotifications,
      pageSize: 10
    });

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const latestFilter: IFilterAttribute = {
      key: "latestDaysNotifications",
      value: ["10"],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(latestFilter);

    this.filterModel.addSort({ key: "createdAt", isAsc: false });
  };

  @action
  loadLastNotifications = () => {
    this.filterModel.loadData();
  };

  @action
  setLatestNotifications = (data: FP.Entities.INotification[]) => {
    this.latestNotifications = data.slice();
    this.isDataLoaded = true;
  };

  loadAllNotifications = () => {};

  setNotificationsAsRead = (notificationsId: number[]) => {};
}
