import { action, observable } from "mobx";
import React from "react";
import { AppService } from "strikejs-app-service";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import { PaginationModel } from "../../../../../../components/widgets/pagination/Pagination_model";
import { LOCAL_STORAGE_FILTERS, Services } from "../../../../../../constants";
import {
  FilterModel,
  FilterOperator,
  IFilterAttribute,
  IFilterModel
} from "../../../../../../core/filter/Filter_model";
import { IHttpProgressModel } from "../../../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../../../core/localization/I18n";
import { IModalService } from "../../../../../../core/modal/IModalService";
import { Animations } from "../../../../../../core/util/Animations";
import { Enums } from "../../../../../../enums";
import { IFilteredOptions } from "../../../../../../services/api/filteredApi/FilteredApiModel";
import { IProjectIndividualUserPermissionsApi } from "../../../../../../services/api/v1/projectIndividualUserPermissions/IProjectIndividualUserPermissions.api";
import { GetIndividualUserPermissionsColumns } from "./IndividualUserPermission_columns";
import { GetProjectIndividualUserFilterFields } from "./IndividualUserPermission_fields";
import { IndividualUserPermissionForm } from "./IndividualUserPermission_form";

export class IndividualUserPermissionsModel {
  filterModel: IFilterModel<FP.Entities.IUserPermission>;
  @observable isLoading: boolean = true;
  @observable users: FP.Entities.IUserPermission[] = [];
  paginationModel;
  appService: AppService;
  organisationId: number;
  projectId: number;
  modalService: IModalService;
  smartTableConfig: any;
  userPermissionsProvider: IProjectIndividualUserPermissionsApi;
  productTourSteps: any;
  httpProgress: IHttpProgressModel;
  showTour: boolean;

  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number) {
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.userPermissionsProvider = this.appService.getService<IProjectIndividualUserPermissionsApi>(
      Services.ProjectIndividualUserPermissionsApi
    );

    this.smartTableConfig = GetIndividualUserPermissionsColumns(organisationId, this);
    this.installPagination();
    this.installFilter();
    this.setupTourSteps();
    this.showTour = false;
  }

  installFilter = () => {
    let config = {
      appService: this.appService,
      paginationModel: this.paginationModel,
      initOpts: {
        filterCb: async (filters: Partial<IFilteredOptions>) => {
          return await this.userPermissionsProvider.getUsersInProject(this.organisationId, this.projectId, filters);
        }
      },
      localStorageName: LOCAL_STORAGE_FILTERS.PROJECT_INDIVIDUAL_USERS
    };
    this.filterModel = new FilterModel(config);

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "userId",
      label: I18n.t("filters.user"),
      value: [],
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (e: any, s) => {
        return `${e.firstName} ${e.lastName}`;
      }
    };

    const permissionLevelFilter: IFilterAttribute = {
      key: "permissionLevel",
      label: I18n.t("filters.permissionLevel"),
      value: [],
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.key);
      },
      valueRenderer: (e: any, s) => {
        return `${e.label}`;
      }
    };

    const searchPhraseFilter: IFilterAttribute = {
      key: "searchPhrase",
      label: I18n.t("filters.searchPhrase"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.filterModel.addSort({
      key: "id",
      isAsc: true
    });
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(permissionLevelFilter);
    this.filterModel.addFilter(searchPhraseFilter);
    this.filterModel.setConfig({
      formFields: GetProjectIndividualUserFilterFields(this.organisationId, this.projectId),
      onDataLoaded: d => this.setUsers(d)
    });
  };

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("searchPhrase", e.value);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  onMount = (organisationId: number) => {
    this.loadUsers();
  };

  onUnmount = () => {};

  @action
  loadUsers = async () => {
    await this.filterModel.loadData();
  };

  addUser = async users => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.userPermissionsProvider.create(this.organisationId, this.projectId, users);
    if (!res) return;
    this.loadUsers();
    this.httpProgress.hideTopProgressBarVisible();
  };

  updateUserPermission = async (user: FP.Entities.IUserPermission) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.userPermissionsProvider.update(this.organisationId, this.projectId, user.userId, user);
    if (!res) return;
    this.loadUsers();
    this.httpProgress.hideTopProgressBarVisible();
  };

  removeUser = async (userId: number) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.userPermissionsProvider.remove(this.organisationId, this.projectId, userId);

    if (!res) return;
    this.loadUsers();
    this.httpProgress.hideTopProgressBarVisible();
  };

  @action
  setUsers = users => {
    this.isLoading = true;
    this.users = users;
    this.isLoading = false;
  };

  showConfirmDeleteModal = item => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeIndividualUserFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmUserRemove", { name: item.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeUser(item.userId);
          this.loadUsers();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  showAddIndividualUserForm = () => {
    this.modalService.show({
      showClose: true,
      title: null,
      content: (
        <IndividualUserPermissionForm
          projectId={this.projectId}
          addUserToProject={async users => {
            this.addUser(users);
            this.modalService.hide();
          }}
          hideModal={() => this.modalService.hide()}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right"
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
        speed: 5
      },
      actions: []
    });
  };

  setupTourSteps = () => {
    this.productTourSteps = [];
  };
}
