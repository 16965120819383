import { action, observable } from "mobx";
import React from "react";
import { AppService } from "strikejs-app-service";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { Services } from "../../../../../constants";
import { FilterModel, FilterOperator, IFilterAttribute } from "../../../../../core/filter/Filter_model";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../core/util/Animations";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { Enums } from "../../../../../enums";
import { IFlightPathApiResponse } from "../../../../../services/api/BaseApiModel";
import { ICustomPropertiesApi } from "../../../../../services/api/v1/customProperties/ICustomPropertiesApi";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { GetCustomPropertiesTableConfig } from "./CustomProperties_config";
import { getCustomPropertiesFormFields } from "./CustomProperties_fields";

export class CustomPropertiesModel extends BaseModel {
  appService: AppService;
  canEditOrganisation: boolean;
  customPropertiesProvider: ICustomPropertiesApi;
  filterModel: FilterModel<FP.Entities.ICustomProperty>;
  paginationModel: PaginationModel;
  @observable.ref customProperties: FP.Entities.ICustomProperty[];
  organisationId: number;
  tableModel: ITableModel<FP.Entities.ICustomProperty>;
  httpProgress: IHttpProgressModel;
  modalService: IModalService;
  formModel: SingleFormModel;
  projectId: number;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.customPropertiesProvider = this.appService.getService<ICustomPropertiesApi>(Services.CustomPropertiesApi);

    this.formModel = new SingleFormModel();
    this.installPagination();
    this.installFilter();
    this.initTable();
  }

  initTable = () => {
    this.tableModel = new TableModel();
    const tableConfig = GetCustomPropertiesTableConfig(this);
    this.tableModel.set(tableConfig);
  };

  onMount = (canEditOrganisation: boolean) => {
    this.canEditOrganisation = canEditOrganisation;
  };
  onUnmount = () => {};

  loadCustomProperties = async (organsiationId: number) => {
    await this.filterModel.loadData();
  };

  setCustomPropertyIsRequired = async (customPropertyId: number, value: boolean) => {
    return this.customPropertiesProvider.setProjectPropertyIsRequired(
      this.organisationId,
      this.projectId,
      customPropertyId,
      value
    );
  };

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      paginationModel: this.paginationModel,
      initOpts: {
        filterCb: async filterOptions =>
          await this.customPropertiesProvider.getProjectFiltered(this.organisationId, this.projectId, filterOptions)
      }
    };
    this.filterModel = new FilterModel(config);

    const organisationFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.organisationId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "name",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addSort({
      key: "name",
      isAsc: true
    });

    this.filterModel.addFilter(organisationFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.setConfig({
      formFields: null,
      onDataLoaded: this.setCustomProperties
    });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  @action
  setCustomProperties = (customProperties: FP.Entities.ICustomProperty[]) => {
    this.customProperties = customProperties;
    this.tableModel.setData(customProperties);
  };

  showFormModal = (item?: FP.Entities.ICustomProperty) => {
    this.formModel.formFields = getCustomPropertiesFormFields(this.organisationId, item);
    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateCustomPropertiesButton",
        label: I18n.t("phrases.save"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          className: "ml-2"
        },
        onAction: async () => {
          const formRes = await this.formModel.submit();
          if (!formRes) return;

          let res: IFlightPathApiResponse<FP.Entities.ICustomProperty>;

          this.modalService.hide();
          this.httpProgress.showOverlay();
          if (item) {
            res = await this.customPropertiesProvider.updateProjectProperty(
              this.organisationId,
              this.projectId,
              item.id,
              formRes as FP.Entities.ICustomProperty
            );
          } else {
            res = await this.customPropertiesProvider.createProjectProperty(
              this.organisationId,
              this.projectId,
              formRes as FP.Entities.ICustomProperty
            );
          }
          this.httpProgress.hideOverlay();

          if (!res || res.isError) return;

          this.loadCustomProperties(this.organisationId);
        }
      }
    ];

    this.modalService.show({
      title: <h4 className="mt-6">{I18n.t("phrases.createCustomProperty")}</h4>,
      showClose: true,
      content: <div className="container-fluid">{this.formModel.renderComponent()}</div>,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
        speed: 5
      }
    });
  };

  showConfirmDeleteModal = (item: FP.Entities.ICustomProperty) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteCustomProperty")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDelete", { name: item.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();

          this.httpProgress.showOverlay();
          const res = await this.customPropertiesProvider.removeProjectProperty(
            this.organisationId,
            this.projectId,
            item.id
          );
          this.httpProgress.hideOverlay();

          if (!res || res.isError) return;

          this.loadCustomProperties(this.organisationId);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
