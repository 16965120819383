import * as React from "react";
import { ProgrammePermissionModel } from "./ProgrammePermissions_model";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { LinkButton, Button, ButtonTypes } from "../../../../../components/ui/Button";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { TableView } from "../../../../../core/table/Table_view";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Animations } from "../../../../../core/util/Animations";

export interface ProgrammePermissionsProps {
  model: ProgrammePermissionModel;
}

export const ProgrammePermissions: React.FC<ProgrammePermissionsProps> = observer(({ model }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  React.useEffect(() => {
    model.onMount(+organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h1 className="d-inline-block">
            {model.programme.name} | {I18n.t("phrases.managePermissions")}
          </h1>
          <LinkButton
            type={ButtonTypes.OUTLINE_PRIMARY}
            href={`/organisations/${organisationId}/programmes/${model.programme?.id}`}
            className="float-right"
          >
            {I18n.t("phrases.closeSettings")}
          </LinkButton>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("phrases.manageProjectPermissionsDescirption")}</p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Button
            onClick={() => {
              model.showAddUserModal();
            }}
          >
            {I18n.t("phrases.addUser")}
          </Button>

          <SearchButton className={"float-right"} value={""} onChange={() => { }} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          {model.isLoading ? (
            <PositionedSpinner />
          ) : (
            <>
              <TableView model={model.tableModel} />
              <Pagination model={model.paginationModel} />
            </>
          )}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <h2>Users who inherit access</h2>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          {model.isInheritLoading ? <PositionedSpinner /> : <TableView model={model.inheritUserTable} />}
        </div>
      </div>
    </div>
  );
});
