import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { BaseProjectApiModel } from "../../BaseProjectApiModel";
import { IHttp } from "../../IHttp";
import { IImpactGroupVisualisationsApi } from "./IImpactGroupVIsualisations.api";

export class ImpactGroupVisualisationsApi extends BaseProjectApiModel<any> implements IImpactGroupVisualisationsApi {
  controller: string = "impact-group-visualisations";

  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "impact-group-visualisations");
    this.url = `${this.http.url}/${this.version}/organisations/`;
  }

  getImpactGroupSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-group-visualisations/summary`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };
}
