import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../contexts/AppService";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { Enums } from "../../../../enums";
import { useFlightPathUser } from "../../../../setup";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { EditActionViewModel } from "./EditActionView_model";

export interface EditActionViewProps {
  model?: EditActionViewModel;
}

const EditActionView: React.FunctionComponent<EditActionViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const { projectId, actionId } = useParams<{ projectId: string; actionId: string }>();
  const [model] = useState(
    () => m || new EditActionViewModel({ appService, user, projectId: +projectId, actionId: +actionId })
  );

  useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  return (
    <Async promiseFn={model.loadAction}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <div className="action-extended-view data-extended-view pt-8">
          <div className="container-fluid data-extended-view__block data-extended-view__block--header">
            <div className="row">
              <div className="col p-2">
                <LinkButton size={Enums.UiSizes.MD} type={ButtonTypes.LINK} className="p-0" href={model.returnUrl}>
                  <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                  {I18n.t("phrases.back")}
                </LinkButton>
              </div>
            </div>
          </div>
          <div className="data-extended-view__block data-extended-view__block--wrapper">
            <div className={`data-extended-view__col pt-4 container-fluid ${Animations.FP_ZOOM_IN} speed-5`}>
              <div className="row mb-5">
                <div className="col-lg-7">
                  <h1>{I18n.t("phrases.editAction")}</h1>
                  <p className="mb-0">{I18n.t("phrases.editActionDescription")}</p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <Panel.Panel hasBorderRadius={true} type={Panel.PanelTypes.OUTLINES} className="p-3 col-6">
                    <SingleForm model={model.formModel} />
                  </Panel.Panel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Async.Resolved>
    </Async>
  );
});

export { EditActionView };
