import _ from "lodash";
import React from "react";
import AppService from "../../../../../contexts/AppService";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, Services } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IMultiInputModel } from "../../../../../core/forms/controls/multiInput/IMultiInputModel";
import { INIT_MULTIINPUT } from "../../../../../core/forms/controls/multiInput/MultiInput_model";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { TextFieldModel } from "../../../../../core/forms/controls/textField/TextField_model";
import { IFormFieldModel } from "../../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";
import { ICustomPropertyEntityTypesApi } from "../../../../../services/api/v1/customPropertyEntityTypes/ICustomPropertyEntityTypesApi";
import { ICustomPropertyTypesApi } from "../../../../../services/api/v1/customPropertyTypes/ICustomPropertyTypes";

export const getCustomPropertiesFormFields = (organisationId: number, item: FP.Entities.ICustomProperty) => {
  const customPropertyTypesProvider = AppService.getService<ICustomPropertyTypesApi>(Services.CustomPropertyTypesApi);
  const customPropertyEntityTypeProvider = AppService.getService<ICustomPropertyEntityTypesApi>(
    Services.CustomPropertyEntityTypesApi
  );
  const org: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.customPropertyName"),
    label: <label htmlFor="name">{I18n.t("forms.customPropertyName")} *</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: TextFieldModel = this;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="name">{I18n.t("validations.customPropertyName")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    value: item?.name
  };

  const description: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "description",
    inputType: "textarea",
    placeholder: I18n.t("placeholders.customPropertyDescription"),
    label: <label htmlFor="name">{I18n.t("forms.customPropertyDescription")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: item?.name
  };

  const entityType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "customPropertyEntityTypeId",
    label: <label htmlFor={"customPropertyEntityTypeId"}>{I18n.t("forms.customPropertyEntityTypeId")} *</label>,
    placeholder: I18n.t("placeholders.searchCustomPropertyEntityTypeId"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        const res = await customPropertyEntityTypeProvider.getAll(organisationId);

        if (res?.payload) {
          const sortedTypes = _.orderBy(res.payload, [type => type.name.toLowerCase()]);
          model.setOptions(sortedTypes);
        }
      }
    },
    componentProps: {
      className: "form-control"
    },
    searchAttribute: "name",
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.customPropertyTypeRequired")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.id;
    }
  };

  const propertyType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "customPropertyTypeId",
    label: <label htmlFor={"customPropertyTypeId"}>{I18n.t("forms.customPropertyTypeId")} *</label>,
    placeholder: I18n.t("placeholders.searchCustomPropertyTypeId"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        const res = await customPropertyTypesProvider.getAll(organisationId);

        if (res?.payload) {
          const sortedTypes = _.orderBy(res.payload, [type => type.name.toLowerCase()]);
          model.setOptions(sortedTypes);
        }
      }
    },
    componentProps: {
      className: "form-control"
    },
    onItemSelected: function () {},
    searchAttribute: "name",
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.customPropertyTypeRequired")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.id;
    }
  };

  const multiInput: Partial<IMultiInputModel> = {
    ...INIT_MULTIINPUT,
    key: "propertyOptions",
    label: <label htmlFor={"propertyOptions"}>{I18n.t("forms.multiSelectOptions")} *</label>,
    placeholder: I18n.t("placeholders.searchMultiSelectOptions"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["customPropertyTypeId"],
    isHidden: true,
    onChannelFieldChanged: function (field: IFormFieldModel<any, any>) {
      this.isHidden = field.value.id !== 6;
    },
    valueLabelFn: s => {
      return s.label;
    }
  };

  const fields = [];

  fields.push(org);
  fields.push(name);
  fields.push(description);
  fields.push(entityType);
  fields.push(propertyType);
  fields.push(multiInput);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
