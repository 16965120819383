import * as React from "react";
import { ITableConfig } from "../../../../core/table/ITableModel";
import I18n from "../../../../core/localization/I18n";
import { IStGroupStakeholder } from "./AddStakeholderGroup_model";
import { convertStakeholderToName } from "../../../../core/util/Helpers";

export const GetCompactStakeholderTableConfig = (): ITableConfig<IStGroupStakeholder> => {
  return {
    colHeaders: [
      {
        key: "stakeholder",
        content: <h5 className="mb-0">{I18n.t("table.stakeholder")}</h5>,
        selector: (obj: IStGroupStakeholder) => convertStakeholderToName(obj)
      },
      {
        key: "isInProject",
        content: <h5 className="mb-0">{I18n.t("table.alreadyInProject")}</h5>,
        selector: (obj: IStGroupStakeholder) => {
          return <p className="mb-0">{I18n.t(obj.isInProject ? "phrases.yes" : "phrases.no")}</p>;
        }
      },
      {
        key: "role",
        content: <h5 className="mb-0">{I18n.t("table.role")}</h5>,
        selector: (obj: IStGroupStakeholder) => {
          return obj.role?.name || "";
        }
      },
      {
        key: "businessArea",
        content: <h5 className="mb-0">{I18n.t("table.businessArea")}</h5>,
        selector: (obj: IStGroupStakeholder) => {
          return obj.businessArea?.name || "";
        }
      },
      {
        key: "owner",
        content: <h5 className="mb-0">{I18n.t("table.relationshipOwner")}</h5>,
        selector: (obj: IStGroupStakeholder) => {
          return obj.owner ? `${obj.owner.firstName} ${obj.owner.lastName}` : "";
        }
      }
    ] as any[]
  };
};
