import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Enums } from "../../../../../enums";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { Services } from "../../../../../constants";

export class ImpactStakeholderListViewModel extends BaseModel {
  impactGroupProvider: IImpactGroupsApi;
  appService: AppService;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref stakeholders: FP.Entities.IStakeholder[] = [];
  @observable impactGroupStakeholders;
  @observable.ref filteredStakeholders: FP.Entities.IStakeholder[] = [];
  projectId: number;
  impactGroupId: number;

  constructor(appService: AppService, projectId: number, impactGroupId: number) {
    super();
    this.appService = appService;
    this.impactGroupId = impactGroupId;
    this.projectId = projectId;
    this.impactGroupProvider = appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
  }

  onMount = () => { };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterStakeholders();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterStakeholders();
    this.hideSearchMode();
  };

  @action
  filterStakeholders = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredStakeholders = this.stakeholders.filter(stakeholder => {
        const name =
          stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
            ? `${stakeholder.firstName} ${stakeholder.lastName}`
            : stakeholder.firstName;
        const lowerName = name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredStakeholders = this.stakeholders;
    }
  };

  @action
  loadImpactGroupStakeholders = (organisationId => async () => {
    let res = await this.impactGroupProvider.getImpactGroupStakeholders(organisationId, this.projectId, this.impactGroupId);
    if (!res) return;
    this.stakeholders = res.payload;
    this.filteredStakeholders = res.payload;
  })
}
