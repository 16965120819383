import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { BaseProjectApiModel } from "../../BaseProjectApiModel";
import { IHttp } from "../../IHttp";
import { IActionVisualisationsApi } from "./IActionVisualisation.api";

export class ActionVisualisationsApi extends BaseProjectApiModel<any> implements IActionVisualisationsApi {
  controller: string = "action-visualisations";

  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "action-visualisations");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getUnmitigatedActionSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/summary/incomplete-actions`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsSetToCompleteSummary = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/summary/actions-set-to-complete`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsOverdueSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/summary/overdue-actions`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getUpcomingActionsSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/summary/upcoming-actions`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getTopActionTypesSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/summary/action-types`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsProgressSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/action-visualisations/summary/actions-progress`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsProgressTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/action-visualisations/totals/action-progress`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsRagTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/action-visualisations/totals/action-rag`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsProgressPercentageTotals = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/action-visualisations/totals/action-progress-percentage`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getActionsRagPercentageTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/action-visualisations/totals/action-rag-percentage`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };
}
