import { IAppService, Services } from "../../constants";
import { Application } from "../../Application";
import { IApp } from "../../IApp";
import { ChangeServices } from "./services";
import { INavigationModel } from "../../components/widgets/navigation/Navigation_model";
import { GetMainSelectionFromUrl } from "../../pages/change/navigation/config";
import { IOrganisationContextModel } from "../../services/local/organisationContext/IOrganisationContextModel";
import I18n from "../../core/localization/I18n";
import { IHttp } from "../../services/api/IHttp";
import { FirebaseService } from "../../services/external/firebase/FirebaseService";
import { AxiosResponse } from "axios";
import { AppService } from "strikejs-app-service";

export class ChangeApp implements IApp {
  private _called: boolean = false;
  appService: AppService;

  install = async (app: Application) => {
    if (this._called) {
      return;
    }
    this._called = true;
    await this.installLocale();
    this.installServices(app);
    this.createApp(app);
  };

  installLocale = async () => {
    let firebaseService = new FirebaseService("en-gb");
    I18n.setLocale("en-gb");
    let res = await firebaseService.load();
    I18n.setData(res);
  };

  installServices = (app: Application) => {
    const { appService } = app;
    this.appService = appService;
    let services = ChangeServices(appService);

    services.forEach((service: IAppService) => {
      appService.setService(service.label, service.cb);
    });

    let httpService = appService.getService<IHttp>(Services.Http);

    httpService.onGetSuccess = (response: AxiosResponse) => {
      let orgContext = appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
      let orgId = response.data.orgId;
      let org = orgContext.getCurrentOrganisation();
      if (org && orgId !== 0 && orgId !== org.id) {
        let cachedOrg = orgContext.getOrganisation(orgId);
        if (cachedOrg) {
          orgContext.setCurrentOrganisation(cachedOrg);
        } else {
          window.location.href = "";
        }
      }
    };

    httpService.onGetError = (response: AxiosResponse) => {
      if (response.status !== 403) return;

      let orgContext = appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
      let orgId = response.data.orgId;
      let org = orgContext.getCurrentOrganisation();
      if (org && orgId !== 0 && orgId !== org.id) {
        let cachedOrg = orgContext.getOrganisation(orgId);
        if (cachedOrg) {
          orgContext.setCurrentOrganisation(cachedOrg);
        } else {
          window.location.href = "/organisations/no-access";
        }
      }
    };
    let navigationModel = appService.getService<INavigationModel>(Services.NavigationModel);
    let initSelection = GetMainSelectionFromUrl(window.location.href.replace(appConfig.baseUrl, ""));
    navigationModel.setCurrentContentKey(initSelection);
  };

  createApp(app: Application) { }
}
