import * as React from "react";
import _ from "lodash";
import { IModel, BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import {
  IInnerNavContentConfig,
  IInnerNavContentModel,
  InnerNavContentModel
} from "../../../../components/widgets/innerNavigation/InnerNavContent_model";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { Services } from "../../../../constants";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { observable, action } from "mobx";
import { IModalService } from "../../../../core/modal/IModalService";
import I18n from "../../../../core/localization/I18n";
import { RLink } from "../../../../components/ui/Rlink";
import { ManageValuesSettings } from "./manageValueSettings/ManageValueSettings_view";
import { ManageValuesSettingsModel } from "./manageValueSettings/ManageValueSettings_model";
import { GeneralSettings } from "./generalSettings/GeneralSettings_view";
import { GeneralSettingsModel } from "./generalSettings/GeneralSettings_model";
import OrganisationSettingsContext from "../../../../contexts/organisationSettings/OrganisationSettingsContext";

export interface ISettingsViewModel extends IModel {
  innerNavContentModel: IInnerNavContentModel;
  isLoading: boolean;
  organisation: FP.Entities.IOrganisation;
}

export class SettingsViewModel extends BaseModel implements ISettingsViewModel {
  innerNavContentConfig: IInnerNavContentConfig;
  innerNavContentModel: IInnerNavContentModel;
  organisationContext: IOrganisationContextModel;
  orgProvider: IOrganisationsApi;
  modalService: IModalService;
  routeProps: RouteComponentProps;
  @observable isLoading: boolean = true;
  @observable.ref organisation: FP.Entities.IOrganisation;
  isAdmin: boolean;
  organisationId: number;
  appService: AppService;
  manageValuesSettingsModel: ManageValuesSettingsModel;
  generalSettingsModel: GeneralSettingsModel;
  customPropertyId: number;
  /**
   *
   */
  constructor(
    appService: AppService,
    organisationId: number,
    routeProps: RouteComponentProps,
    refreshClaims: () => void,
    isAdmin: boolean,
    customPropertyId: number
  ) {
    super();
    this.isAdmin = isAdmin;
    this.organisationContext = appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.orgProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.innerNavContentModel = new InnerNavContentModel(this.innerNavContentConfig);
    this.customPropertyId = customPropertyId;
    this.organisationId = organisationId;
    this.routeProps = routeProps;
    this.appService = appService;

    this.loadOrganisation(organisationId);
  }

  showCustomProperies = async () => {
    return this.isAdmin && OrganisationSettingsContext.isCustomPropertiesEnabled();
  };

  @action
  loadOrganisation = async (id: number) => {
    this.isLoading = true;
    let res = await this.orgProvider.getByIdAsync(id);

    if (!res || res.isError) {
      return;
    }
    this.organisation = res.payload;
    this.manageValuesSettingsModel = new ManageValuesSettingsModel(this.appService, this.organisationId);
    this.generalSettingsModel = new GeneralSettingsModel(this.appService, this.organisationId);
    let contentItems = {
      general: {
        key: "general",
        label: I18n.t("phrases.generalSettings"),
        content: <GeneralSettings model={this.generalSettingsModel} />
      },
      manageValues: {
        key: "manageValues",
        label: I18n.t("phrases.manageValues"),
        content: <ManageValuesSettings model={this.manageValuesSettingsModel} />
      }
    };

    this.innerNavContentConfig = {
      navigationTitle: "Settings",
      displayNavWithLinks: true,
      linkRender: e => {
        return (
          <RLink
            to={`/organisations/${this.organisation.id}/settings/customProperties/${this.customPropertyId}/${e.key}`}
          >
            <h4 id={`inner-nav-${e.key}`} className="mb-0">
              {e.label}
            </h4>
          </RLink>
        );
      },
      items: contentItems
    };
    // test change
    this.innerNavContentModel.setConfig(this.innerNavContentConfig);
    let page = this.routeProps.match.params["key"];
    let keys = _.map(this.innerNavContentConfig.items, e => e.key);
    if (page && keys.indexOf(page) >= 0) {
      this.innerNavContentModel.setCurrentViewKey(page);
    }
    this.isLoading = false;
  };
}
