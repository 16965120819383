import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action, runInAction } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { Services } from "../../../../constants";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { appHistory } from "../../../../setup";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { getImpactGroupForm } from "../../forms/impactGroups/ImpactGroupFormSection_data";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

interface IEditImpactGropViewModelOptions {
  appService: AppService;
  impactGroupId: number;
  projectId: number;
  organisationId: number;
  user: FP.Entities.IUser;
}

export class EditImpactGroupViewModel extends BaseModel {
  appService: AppService;
  localStorageService: ILocalStorageService;
  projectId: number;
  impactGroupId: number;
  impactProvider: IImpactsApi;
  orgId: number;
  orgProvider: IOrganisationsApi;
  impactGroupProvider: IImpactGroupsApi;
  httpProgress: IHttpProgressModel;
  actionTypeProvider: IActionTypeApi;
  @observable isLoading: boolean = true;
  @observable.ref impactGroup: FP.Entities.IImpactGroup;
  @observable.ref formModel: SingleFormModel;
  user: FP.Entities.IUser;
  returnUrl: string;

  constructor({ appService, projectId, impactGroupId, user, organisationId }: IEditImpactGropViewModelOptions) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.impactGroupId = impactGroupId;
    this.user = user;

    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactGroupProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.actionTypeProvider = this.appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.formModel = new SingleFormModel();
    this.orgId = organisationId;

    const queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);

    // check if there is a previous url so it redirects with the previous url in the string params
    const prevUrl = queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");
    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.orgId}/projects/${this.projectId}/impactgroups/${impactGroupId}${
        prevUrl ? "?" + QUERY_STRING_PARAMS.RETURN_URL + "=" + encodeURIComponent(prevUrl) : ""
      }`
    );
  }

  onMount = async () => {
    await this.loadImpactGroup();
    this.formModel.formFields = getImpactGroupForm(this.impactProvider, this.projectId, this.orgId, this.impactGroup);

    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto",
          href: `/organisations/${this.orgId}/projects/${this.projectId}/impactGroups/${this.impactGroupId}`
        }
      },
      {
        id: "save",
        label: I18n.t("phrases.save"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        },
        onAction: this.updateImpactGroup
      }
    ];
  };

  onUnmount = () => {};

  @action
  loadImpactGroup = async () => {
    let res = await this.impactGroupProvider.getDetailed(this.orgId, this.projectId, this.impactGroupId);
    let impactsRes = await this.impactGroupProvider.getImpactGroupImpacts(
      this.orgId,
      this.projectId,
      this.impactGroupId
    );
    if (!res || res.isError || !impactsRes || impactsRes.isError) return;

    runInAction(() => {
      this.impactGroup = res.payload;
      this.impactGroup.impacts = impactsRes.payload;
    });
  };

  @action
  updateImpactGroup = async () => {
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;
    res.refNumber = (this.impactGroup as any).refNumber;
    this.httpProgress.showOverlay();
    let result = await this.impactGroupProvider.update(
      this.orgId,
      this.projectId,
      this.impactGroupId,
      res as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    this.impactGroup = result.payload;
    appHistory.push(this.returnUrl);
  };
}
