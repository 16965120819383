import * as React from 'react';
import { IImageCropperModel } from "../../../../../core/forms/controls/imageCropper/IImageCropperModel";
import { INIT_IMAGE_CROPPER_FIELD } from "../../../../../core/forms/controls/imageCropper/ImageCropper_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import I18n from "../../../../../core/localization/I18n";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { Validations } from '../../../../../core/forms/helpers/Validations';
import { ErrorMessage } from '../../../../../components/ui/ErrorMessage';
import { IUsersApi } from '../../../../../services/api/v1/users/IUsers.api';
import { generateFormFieldsFromJson } from '../../../../../core/forms/helpers/FormFieldMappers';
import { generateUserImage } from '../../../../../core/util/Helpers';
import { FORM_COL } from '../../../../../constants';

export const GetGeneralSettingsFields = (
    usersProvider: IUsersApi,
    modalService: IModalService,
    user: FP.Entities.IUser) => {


    const profPic: Partial<IImageCropperModel> = {
        ...INIT_IMAGE_CROPPER_FIELD,
        key: "profileImage",
        value: { url: user.profileImageUrl || generateUserImage(user), blob: null, base64: "" },
        modalService: modalService,
        orignalImageSource: user?.profileImageUrl,
        avatarLabel: I18n.t("phrases.changeUserImage"),
        fieldClassName: FORM_COL.FULL_WIDTH + " col-lg-6 col-space-lg-6",
    };


    const firstName: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "firstName",
        label: <label htmlFor={"firstName"}>{I18n.t("forms.firstName")} *</label>,
        placeholder: I18n.t("placeholders.firstName"),
        className: "input--underline",
        fieldClassName: FORM_COL.FULL_WIDTH + " col-lg-6 col-space-lg-6",
        validate: function () {
            let self: ITextFieldModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.value)) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.firstName")}</ErrorMessage>
                res = false;
            }

            return res;
        },
        value: user?.firstName
    }

    const lastName: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "lastName",
        label: <label htmlFor={"lastName"}>{I18n.t("forms.lastName")} *</label>,
        placeholder: I18n.t("placeholders.lastName"),
        className: "input--underline",
        fieldClassName: FORM_COL.FULL_WIDTH + " col-lg-6 col-space-lg-6",
        validate: function () {
            let self: ITextFieldModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.value)) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.lastName")}</ErrorMessage>
                res = false;
            }

            return res;
        },
        value: user?.lastName
    }


    const email: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "email",
        label: <label htmlFor="email">{I18n.t("forms.email")} *</label>,
        placeholder: I18n.t("placeholders.email"),
        className: "input--underline",
        fieldClassName: FORM_COL.FULL_WIDTH + " col-lg-6 col-space-lg-6",
        validate: async function () {
            let self: ITextFieldModel = this;
            let errorMessages: React.ReactNode[] = [];
            let res = true;

            if (Validations.IS_EMPTY(self.value)) {
                errorMessages.push(<ErrorMessage>{I18n.t("validations.email")}</ErrorMessage>);
                res = false;
            }

            if (!Validations.IS_EMAIL(self.value)) {
                errorMessages.push(<ErrorMessage>{I18n.t("validations.validEmail")}</ErrorMessage>);
                res = false;
            }

            // if (Validations.IS_EMAIL(self.value)) {
            //     const response = await usersProvider.getFilteredAsync({ filters: `email==${self.value}` });

            //     if (response.payload?.length && user?.id !== response.payload[0].id) {
            //         errorMessages.push(<ErrorMessage>{I18n.t("validations.userEmailAlreadyInUse")}</ErrorMessage>);
            //         res = false;
            //     }
            // }


            self.errorMessage = errorMessages.length ? errorMessages : null;
            return res;
        },
        value: user?.email
    }

    const fields = [];

    fields.push(profPic);
    fields.push(firstName);
    fields.push(lastName);
    fields.push(email);

    const models = generateFormFieldsFromJson(fields);
    return models;
}