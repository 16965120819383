import React, { useContext } from "react";
import { IPermissionsContextModel, PermissionContextModel } from "./PermissionsContextModel";

let PermissionsContext = new PermissionContextModel();

const PermissionsReactContext = React.createContext<IPermissionsContextModel>(PermissionsContext);

export const PermissionContextProvider: React.FC<{ permissionContext: IPermissionsContextModel }> = ({
  permissionContext,
  children
}) => <PermissionsReactContext.Provider value={permissionContext}>{children}</PermissionsReactContext.Provider>;

export function usePermissionContext() {
  return useContext(PermissionsReactContext);
}

export default PermissionsContext as IPermissionsContextModel;
