import * as React from "react";
import { IPaginationModel } from "./Pagination_model";
import { PaginationGroup, PaginationButton } from "../../ui/Pagination";
import { observer } from "mobx-react-lite";
import { UiPositions } from "../../../enums";
import { Icon, IconSymbols } from "../../ui/Icon";
import PaginationDefault from "@flightpath/coreui/dist/ui/Pagination"

export interface PaginationProps {
  model: IPaginationModel;
}

const Pagination: React.FunctionComponent<PaginationProps> = observer(({ model }) => {
  const { currentPage, totalPages, onPageClick } = model.config;

  if (!currentPage || !totalPages) return null;

  const pages = PaginationDefault.getPageList(totalPages, model.currentPage, 7);
  return (
    <PaginationGroup position={UiPositions.RIGHT}>
      <PaginationButton
        status={currentPage === 1 ? "disabled" : null}
        onClick={() => currentPage - 1 <= 0 ? () => { } : onPageClick(currentPage - 1)}
      >
        <Icon symbol={IconSymbols.ChevronLeft} />
      </PaginationButton>
      {
        pages.map((e, i) => {
          if (e === 0) {
            return <Icon key={i}
              symbol={IconSymbols.DotsHorizontal} />
          }
          return (
            <PaginationButton
              key={i}
              status={e === currentPage ? "active" : (null as any)}
              onClick={() => onPageClick(e)}
            >
              {e}
            </PaginationButton>
          );
        })}
      <PaginationButton
        status={currentPage === totalPages ? "disabled" : null}
        onClick={() => currentPage >= totalPages ? () => { } : onPageClick(currentPage + 1)}
      >
        <Icon symbol={IconSymbols.ChevronRight} />
      </PaginationButton>
    </PaginationGroup>
  );
});

export { Pagination };
