import * as React from 'react';
import { observer } from "mobx-react-lite";
import { CreateStakeholderViewModel } from './CreateStakeholderView_model';
import I18n from '../../../../core/localization/I18n';
import { SingleForm } from '../../forms/singleFormModel/SingleForm_view';
import { Panel } from '../../../../components/ui/Panel';

export interface CreateStakeholderViewProps {
  model: CreateStakeholderViewModel;
}

const CreateStakeholderView: React.FunctionComponent<CreateStakeholderViewProps> = observer((props) => {
  const { model } = props;

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  })

  return <div className="create-Stakeholder-view container-fluid pt-8">
    <div className="row mb-4">
      <div className="col-lg-7">
        <h1>{I18n.t("phrases.createNew")} {I18n.t("entities.stakeholder")}</h1>
        <p className="mb-0">{I18n.t("phrases.createStakeholderDescription")}</p>
      </div>
    </div>
    <div className="col-6">
      <Panel.Panel
        hasBorderRadius={true}
        type={Panel.PanelTypes.OUTLINES}
        className="p-4 mb-4"
      >
        <div className="row">
          <div className="col-12">
            <SingleForm
              model={model.formModel} />
          </div>
        </div>
      </Panel.Panel>
    </div>
  </div>;
});

export { CreateStakeholderView };