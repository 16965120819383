import _ from "lodash";
import React from "react";
import AppService from "../../../../../contexts/AppService";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, Services } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IMultiInputModel } from "../../../../../core/forms/controls/multiInput/IMultiInputModel";
import { INIT_MULTIINPUT } from "../../../../../core/forms/controls/multiInput/MultiInput_model";
import { IRadioButtonListModel } from "../../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";
import { ICustomPropertyTypesApi } from "../../../../../services/api/v1/customPropertyTypes/ICustomPropertyTypes";

export const getCustomPropertiesManageValueFormFields = (
  organisationId: number,
  item: FP.Entities.ICustomProperty,
  canEdit: boolean
) => {
  const customPropertyTypesProvider = AppService.getService<ICustomPropertyTypesApi>(Services.CustomPropertyTypesApi);

  const org: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const isRequired: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isRequired",
    label: <label htmlFor="isRequired">{I18n.t("forms.isCustomPropertyRequired")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    options: [
      {
        key: "isRequiredYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupA"
        }
      },
      {
        key: "isRequiredNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupA"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: item?.isRequired ? "yes" : "no"
  };

  const canOverride: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "canOverride",
    label: <label htmlFor="canOverride">{I18n.t("forms.canOverrideCustomProperty")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    options: [
      {
        key: "canOverrideYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupB"
        }
      },
      {
        key: "canOverrideNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupB"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: item?.canOverride ? "yes" : "no"
  };

  const propertyType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "customPropertyTypeId",
    label: <label htmlFor={"customPropertyTypeId"}>{I18n.t("forms.customPropertyTypeId")} *</label>,
    placeholder: I18n.t("placeholders.searchCustomPropertyTypeId"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        const res = await customPropertyTypesProvider.getAll(organisationId);

        if (res?.payload) {
          const sortedTypes = _.orderBy(res.payload, [type => type.name.toLowerCase()]);
          model.setOptions(sortedTypes);
        }
      }
    },
    componentProps: {
      className: "form-control"
    },
    disabled: !canEdit,
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.customPropertyTypeRequired")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.id;
    },
    hintLabel: !canEdit ? I18n.t("phrases.customPropertyInUse") : "",
    value: item?.propertyType
  };

  const multiInput: Partial<IMultiInputModel> = {
    ...INIT_MULTIINPUT,
    key: "propertyOptions",
    label: <label htmlFor={"propertyOptions"}>{I18n.t("forms.multiSelectOptions")} *</label>,
    placeholder: I18n.t("placeholders.searchMultiSelectOptions"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["customPropertyTypeId"],
    isHidden: true,
    onChannelFieldChanged: function (field: IFormFieldModel<any, any>) {
      this.isHidden = field.value.id !== 6;
    },
    valueLabelFn: s => {
      return s.label;
    }
  };

  const defaultValue: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "defaultValue",
    placeholder: I18n.t("placeholders.defaultValue"),
    label: <label htmlFor="defaultValue">{I18n.t("forms.defaultValue")} *</label>,
    subscribeTo: ["customPropertyTypeId"],
    borderStyle: null,
    componentProps: {},
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val && field.key === "customPropertyTypeId" && (val as any).id < 6) {
        this.isHidden = (field.value as any) === "yes";
      } else {
        this.isHidden = true;
      }
    },
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.defaultValue")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    isHidden: item?.propertyType.id === 6,
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: item?.defaultValue
  };

  const multiSelectDefault: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "multiSelectDefault",
    label: <label htmlFor={"multiSelectDefault"}>{I18n.t("forms.defaultValue")}</label>,
    placeholder: I18n.t("placeholders.defaultValue"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.label} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    searchAttribute: "label",
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.label,
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val && field.key === "customPropertyTypeId" && (val as any).id === 6) {
        this.isHidden = (field.value as any) === "yes";
      } else if (field.key === "customPropertyTypeId") {
        this.isHidden = true;
      }
      if (val && field.key === "propertyOptions") {
        this.setOptions(val);
      }
    },
    isHidden: item?.propertyType.id !== 6,
    subscribeTo: ["propertyOptions", "customPropertyTypeId"]
  };
  const fields = [];

  fields.push(org);
  fields.push(isRequired);
  fields.push(canOverride);
  fields.push(propertyType);
  fields.push(multiInput);
  fields.push(defaultValue);
  fields.push(multiSelectDefault);
  const models = generateFormFieldsFromJson(fields);

  return models;
};
