import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CreateStakeholderGroupViewModel } from "./CreateStakeholderGroupView_model";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { Panel } from "../../../../components/ui/Panel";

export interface CreateStakeholderGroupViewProps {
  model: CreateStakeholderGroupViewModel;
}

const CreateStakeholderGroupView: React.FunctionComponent<CreateStakeholderGroupViewProps> = observer(props => {
  const { model } = props;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className="create-Stakeholder-view container-fluid pt-8">
      <div className="row mb-4">
        <div className="col-12">
          <h1>
            {I18n.t("phrases.createNew")} {I18n.t("entities.stakeholderGroup")}
          </h1>
        </div>
        <div className="col-lg-7">
          <p>
            {I18n.t("phrases.stakGrpExp")} {I18n.t("phrases.stakGrpExample")}
          </p>
        </div>
      </div>
      <div className="col-6">
        <Panel.Panel
          hasBorderRadius={true}
          type={Panel.PanelTypes.OUTLINES}
          className="p-4 mb-4"
        >
          <div className="row">
            <div className="col-12">
              <SingleForm
                model={model.formModel} />
            </div>
          </div>
        </Panel.Panel>
      </div>
    </div>
  );
});

export { CreateStakeholderGroupView };
