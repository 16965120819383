import * as React from "react";
import * as _ from "lodash";
import { IModel, BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import {
  IInnerNavContentConfig,
  IInnerNavContentModel,
  InnerNavContentModel
} from "../../../../components/widgets/innerNavigation/InnerNavContent_model";
import { observable, action } from "mobx";
import { ProgrammeSettingsModel } from "./ProgrammeSettings_model";
import { ProgrammeSettings } from "./ProgrammeSettings_view";
import { IProgrammesApi } from "../../../../services/api/v1/programmes/IProgrammes.api";
import { Services } from "../../../../constants";
import { MilestoneSettingsView } from "./milestones/MilestoneSettings_view";
import { ProgrammePermissionModel } from "./permissions/ProgrammePermissions_model";
import { ProgrammePermissions } from "./permissions/ProgrammePermissions_view";
import { RLink } from "../../../../components/ui/Rlink";
import { Enums } from "../../../../enums";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import OrganisationSettingsContext from "../../../../contexts/organisationSettings/OrganisationSettingsContext";

export interface ISettingsViewModel extends IModel {
  innerNavContentModel: IInnerNavContentModel;
  isLoading: boolean;
}

export class SettingsViewModel extends BaseModel implements ISettingsViewModel {
  innerNavContentConfig: IInnerNavContentConfig;
  innerNavContentModel: IInnerNavContentModel;
  programmeSettings: ProgrammeSettingsModel;
  programmesProvider: IProgrammesApi;
  permissionSettings: ProgrammePermissionModel;
  appService: AppService;
  @observable isLoading: boolean = true;
  @observable.ref programme: FP.Entities.IProgramme;
  authUser: FP.Entities.IUser;
  orgId: number;
  page: string;
  isAdmin: boolean;
  /**
   *
   */
  constructor(
    appService: AppService,
    programmeId: number,
    authUser: FP.Entities.IUser,
    refreshClaims: () => void,
    isAdmin: boolean,
    page?: string
  ) {
    super();
    this.appService = appService;
    this.authUser = authUser;
    this.isAdmin = isAdmin;
    this.programmeSettings = new ProgrammeSettingsModel(appService, programmeId, authUser, refreshClaims);
    this.permissionSettings = new ProgrammePermissionModel(appService, programmeId);
    this.programmesProvider = this.appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    let localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.orgId = parseInt(localStorageService.get(Enums.LocalCookies.ORGANISATION_ID), 10);
    this.page = page;

    this.loadProgramme(programmeId);
  }

  loadProgramme = async (id: number) => {
    let res = await this.programmesProvider.getById(this.orgId, id);
    if (!res && res.isError) {
      console.error("could not find programme id");
    }
    this.setProgramme(res.payload);
  };

  showCustomProperies = async () => {
    return this.isAdmin && OrganisationSettingsContext.isCustomPropertiesEnabled();
  };

  @action
  setProgramme = async (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
    this.programmeSettings.programme = this.programme;
    this.permissionSettings.setProgramme(this.programme);
    this.programmeSettings.loadProgramme();
    let contentItems = {
      programme: {
        key: "programme",
        label: "Programme",
        content: <ProgrammeSettings model={this.programmeSettings} />
      },
      permissions: {
        key: "permissions",
        label: "Permissions",
        content: <ProgrammePermissions model={this.permissionSettings} />
      },
      milestones: {
        key: "milestones",
        label: "Milestones",
        content: <MilestoneSettingsView programme={this.programme} />
      }
    };

    this.innerNavContentConfig = {
      items: contentItems,
      displayNavWithLinks: true,
      linkRender: e => (
        <RLink to={`/organisations/${this.orgId}/programmes/${this.programme.id}/settings/${e.key}`}>
          <h4 id={`inner-nav-${e.key}`} className="mb-0">
            {e.label}
          </h4>
        </RLink>
      ),
      navigationTitle: "Settings"
    };

    if (!this.programme.isPrivate) {
      delete this.innerNavContentConfig.items.permissions;
    }
    this.innerNavContentModel = new InnerNavContentModel(this.innerNavContentConfig);
    const page = this.page;
    const keys = _.map(this.innerNavContentConfig.items, e => e.key);
    if (page && keys.indexOf(page) >= 0) {
      this.innerNavContentModel.setCurrentViewKey(page);
    }
    this.isLoading = false;
  };
}
