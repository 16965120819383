import { IModel, BaseModel } from "../../../core/util/BaseModel";
import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../constants";
import { IOrganisationsApi } from "../../../services/api/v1/organisations/IOrganisations.api";
import { OrganisationCreate } from "./OrganisationCreate_View";
import React from "react";
import I18n from "../../../core/localization/I18n";
import { isEmpty } from "lodash";
import { CompanySize, Enums } from "../../../enums";
import { ILocalStorageService } from "../../../services/local/localStorageService/ILocalStorageService";

export interface IOrganisationCreateModel extends IModel {
  actionLabel: string;
  isActive: boolean;
  onActionClick: (ev: React.SyntheticEvent) => void;
  resultMessage: string;
  createdOrganisationId: number;
}

export class OrganisationCreateModel extends BaseModel implements IOrganisationCreateModel {
  initActionLabel: string = I18n.t("phrases.createOrganisation");
  @observable actionLabel: string = this.initActionLabel;
  @observable isActive: boolean = true;
  @observable resultMessage: string;
  @observable createdOrganisationId: number;
  organisationsProvider: IOrganisationsApi;
  localStorageService: ILocalStorageService;

  /**
   *
   */
  constructor(appService: AppService) {
    super();
    this.organisationsProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.localStorageService = appService.getService<ILocalStorageService>(Services.LocalStorageService);
  }

  reset = () => {
    this.actionLabel = this.initActionLabel;
    this.isActive = true;
  };

  setSaving = () => {
    this.actionLabel = "Creating Organisation";
    this.isActive = false;
  };

  onActionClick = async (ev: React.SyntheticEvent) => {
    this.setSaving();
    let organisation = await this.organisationsProvider.createOrgAsync(this.CreateTestOrganisation());

    if (!isEmpty(organisation) && !organisation.isError) {
      this.localStorageService.set(Enums.LocalCookies.ORGANISATION_ID, organisation.payload.id + "");
      this.resultMessage = "New organisation successfully created:";
      this.createdOrganisationId = organisation.payload.id;
    } else {
      this.resultMessage = organisation.message;
    }

    this.reset();
  };

  TestDate = () => {
    return new Date().toLocaleString("en-GB", { timeZone: "UTC" });
  };

  CreateTestOrganisation = () => ({
    name: `Test Organisation: ${this.TestDate()}`,
    ownerId: 0,
    companySize: CompanySize.TINY,
    description: `Test organisation created for functional test run on ${this.TestDate()}`,
    isActive: true,
    locationId: 1
  });

  renderComponent = () => {
    return <OrganisationCreate model={this} />;
  };
}