import * as React from "react";
import { observable, action, computed } from "mobx";
import { ToasterView } from "./Toaster_view";
import { IUiAction, UiActionRenderers } from "../uiAction/IUiAction";
import _ from "lodash";
import { Animations } from "../util/Animations";
import { PanelProps } from "../../components/ui/Panel";
import { IconSymbols } from "../../components/ui/Icon";
import { ButtonTypes } from "../../components/ui/Button";

export enum TOASTER_TOAST_TIME {
  FAST = 3000,
  NORMAL = 5000,
  SLOW = 7000
}

export interface IToasterConfig {
  onDestroy: (toasterModerl: IToasterModel) => void;
  id: number;
  animationOptions?: FP.Generic.IAnimationOptions;
}

export interface IToasterModel {
  actions: IUiAction<any>[];
  actionAlignedTitle?: React.ReactNode;
  config: IToasterConfig;
  duration: number | TOASTER_TOAST_TIME;
  content: React.ReactNode;
  panelProps: PanelProps;
  animationClass: string;
  addActions: (actions: IUiAction<any>[]) => IToasterModel;
  setContent: (content: React.ReactNode) => IToasterModel;
  setPanelProps: (panelProps: PanelProps) => IToasterModel;
  setActionAlignedTitle: (actionAlignedTitle: React.ReactNode) => IToasterModel;
  setConfig: (config: Partial<IToasterConfig>) => void;
  startTimer: (duration: number | TOASTER_TOAST_TIME) => void;
  getIndex: () => number;
  renderComponent: () => React.ReactNode;
  cancelAutoDestroy: () => void;
  resetActions: () => IToasterModel;
}

export class ToasterModel implements IToasterModel {
  initActions: IUiAction<any>[] = [
    {
      id: "close",
      label: "Close",
      onAction: () => {
        this.config.onDestroy(this);
      },
      rendersIn: UiActionRenderers.BUTTON_ICON,
      componentProps: {
        type: ButtonTypes.LINK,
        className: "float-right ml-2",
        symbol: IconSymbols.Close
      }
    }
  ];
  @observable.ref actions: IUiAction<any>[] = this.initActions;
  @observable config: IToasterConfig = null;
  @observable.ref actionAlignedTitle: React.ReactNode;
  duration: number | TOASTER_TOAST_TIME = -1;
  @observable.ref content: React.ReactNode;
  @observable.ref panelProps: PanelProps;
  timeout: NodeJS.Timeout;
  @observable animationOptions: FP.Generic.IAnimationOptions = {
    animateIn: Animations.FP_ZOOM_IN,
    animateOut: Animations.FADE_OUT,
    speed: 6,
    delay: 0
  };

  @observable _animationClass: string = this.animationOptions.animateIn;

  @computed get animationClass() {
    return `animated ${this._animationClass} delay-${this.animationOptions.delay} speed-${this.animationOptions.speed}`;
  }

  /**
   *
   */
  constructor(initOpts?: IToasterConfig) {
    if (initOpts) {
      this.setConfig(initOpts);
    }
  }

  @action
  setConfig = (config: Partial<IToasterConfig>): void => {
    this.config = { ...this.config, ...config };
    if (config.animationOptions) {
      this._animationClass = config.animationOptions.animateIn || this.animationOptions.animateIn;
    }
  };

  startTimer = (duration: number | TOASTER_TOAST_TIME): void => {
    this.duration = duration;
    this.timeout = setTimeout(() => {
      this.config.onDestroy(this);
    }, this.duration) as any;
  };

  @action
  setContent = (content: React.ReactNode): IToasterModel => {
    this.content = content;
    return this;
  };

  @action
  setPanelProps = (panelProps: PanelProps): IToasterModel => {
    this.panelProps = panelProps;
    return this;
  };

  @action setActionAlignedTitle = (title: React.ReactNode): IToasterModel => {
    this.actionAlignedTitle = title;
    return this;
  }

  @action
  addActions = (actions: IUiAction<any>[]) => {
    _.union(this.actions, actions);
    return this;
  };

  @action
  resetActions = () => {
    this.actions = this.initActions;
    return this;
  };

  getIndex = () => {
    return this.config.id;
  };

  cancelAutoDestroy = () => {
    clearTimeout(this.timeout);
  };

  renderComponent = (): React.ReactNode => {
    return <ToasterView model={this} />;
  };
}
