import React, { useEffect } from "react";
import { CreateAudienceViewModel } from "./CreateAudienceView_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { Panel } from "../../../../components/ui/Panel";

interface CreateAudienceViewProps {
  model: CreateAudienceViewModel;
}

const CreateAudienceView: React.FunctionComponent<CreateAudienceViewProps> = observer((props) => {
  const { model } = props;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  })

  return <div className="create-audience-view container-fluid pt-8">
    <div className="row mb-4">
      <div className="col-lg-7">
        <h1>{I18n.t("phrases.createNew")} {I18n.t("entities.audience")}</h1>
        <p className="mb-0">{I18n.t('phrases.stakAudExp')} {I18n.t('phrases.stakAudExample')}</p>
      </div>
    </div>
    <div className="col-6">
      <Panel.Panel
        hasBorderRadius={true}
        type={Panel.PanelTypes.OUTLINES}
        className="p-4 mb-4"
      >
        <div className="row">
          <div className="col-12">
            <SingleForm
              model={model.formModel} />
          </div>
        </div>
      </Panel.Panel>
    </div>
  </div>
})

export { CreateAudienceView };