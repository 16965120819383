import { IQueryStringService } from "./IQueryStringService";

export enum QUERY_STRING_PARAMS {
  RETURN_URL = "returnUrl",
  PREV_RETURN_URL = "pru"
}
export class QueryStringService implements IQueryStringService {
  getByKeyOrDefault = (key: string, defaultUrl: any) => {
    const value = this.getValue(key);
    return value || defaultUrl;
  };

  hasKey = (key: string) => {
    const queryString = this.getQueryString();
    const params = this.getQueryStringParameters(queryString);
    return !!params[key];
  };

  getValue = (key: string) => {
    const queryString = this.getQueryString();
    const params = this.getQueryStringParameters(queryString);
    return params[key];
  };

  getQueryString = () => {
    const urlList = window.location.href.split("?");
    return urlList.length > 1 ? urlList[1] : "";
  };

  getQueryStringParameters = (queryString: string) => {
    const filterArr = queryString.split("&");
    let result = {};
    for (const filter of filterArr) {
      const [key, value] = filter.split("=");
      result[key] = decodeURIComponent(value);
    }
    return result;
  };
}
