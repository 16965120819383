import * as React from "react";
import { GeneralSettingsModel } from "./General_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../../core/localization/I18n";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Animations } from "../../../../../core/util/Animations";

export interface GeneralSettingsProps {
  model: GeneralSettingsModel;
}

export const GeneralSettings: React.FunctionComponent<GeneralSettingsProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-7">
          <h2>General</h2>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <SingleForm
            model={model.formModel} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="settings-view__actions">
            <div className="d-flex justify-content-end">
              <LinkButton href="/" type={ButtonTypes.OUTLINE_PRIMARY} className="float-right">
                {I18n.t("phrases.cancel")}
              </LinkButton>
              <Button onClick={model.updateUser} type={ButtonTypes.PRIMARY} className="float-right ml-2">
                {I18n.t("phrases.save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
