import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { IImageCropperModel } from "../../../../core/forms/controls/imageCropper/IImageCropperModel";
import { INIT_IMAGE_CROPPER_FIELD } from "../../../../core/forms/controls/imageCropper/ImageCropper_model";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IModalService } from "../../../../core/modal/IModalService";
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { FORM_COL, NAME_IMAGE_PLACEHOLDER } from "../../../../constants";
import _ from "lodash";
import I18n from "../../../../core/localization/I18n";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../components/ui/Icon";

export const getOrganisationFormFields = (
  usersProvider: IUsersApi,
  orgProvider: IOrganisationsApi,
  modalService: IModalService,
  org?: FP.Entities.IOrganisation
): IFormFieldModel<any, any>[] => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "id",
    inputType: "hidden",
    value: org && org.id + "",
    defaultValue: org && org.id + ""
  };

  const profPic: Partial<IImageCropperModel> = {
    ...INIT_IMAGE_CROPPER_FIELD,
    key: "profileImage",
    value: { url: org?.profileImageUrl || NAME_IMAGE_PLACEHOLDER(org?.name), blob: null, base64: "" },
    modalService: modalService,
    orignalImageSource: org?.profileImageUrl,
    avatarLabel: I18n.t(org?.profileImageUrl ? "phrases.changeOrgImage" : "phrases.addOrgImage"),
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12"
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    componentProps: {
      autoComplete: "off"
    },
    placeholder: "Enter Organisation name here",
    label: <label htmlFor="name">{I18n.t("forms.organisationName")} *</label>,
    value: org?.name,
    className: "input--underline",
    validate: function () {
      const self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.organisationName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12"
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.overview")}</label>,
    placeholder: I18n.t("placeholders.overview"),
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12",
    value: org?.description,
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.organisationOverviewHint")}</p>
  };


  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: <label htmlFor={"owner"}>{I18n.t("forms.organisationOwner")} *</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    charInputNumber: 1,
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        const res = await orgProvider.getUsers(org.id);
        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          model.setOptions(sortedUsers);
        }
      }
    },
    validate: function () {
      let self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value as any)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.organisationOwner")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    value: org?.owner,
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: (e: FP.Entities.IUser) => `${e.firstName} ${e.lastName} (${e.email})`,
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12",
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };


  const fields = [];

  if (org) fields.push(orgId);

  fields.push(profPic);
  fields.push(name);
  fields.push(owner);
  fields.push(description);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
