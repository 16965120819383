import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { BaseProjectApiModel } from "../../BaseProjectApiModel";
import { IHttp } from "../../IHttp";
import { IImpactVisualisationsApi } from "./IImpactVisualisations.api";

export class ImpactVisualisationsApi extends BaseProjectApiModel<any> implements IImpactVisualisationsApi {
  controller: string = "impact-visualisations";

  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "impact-visualisations");
    this.url = `${this.http.url}/${this.version}/organisations/`;
  }

  getImpactSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/summary`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getMitigatedImpactSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/mitigated-impacts`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactWithoutActionsSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/mitigations`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactMissingDataSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/missing-data`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactMissingStaksholdersSummary = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/missing-stakeholders`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactStatusTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/impact-status-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactLevelTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/impact-level-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getAbsoluteImpactStatusTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/absolute-impact-status-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactAndImpactGroupImpactLevelTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/impact-group-impacts-impact-level-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };
}
