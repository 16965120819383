import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { ITableModel } from "../../../../core/table/ITableModel";
import { TableModel } from "../../../../core/table/Table_model";
import { GetCompactStakeholderTableConfig } from "./CompactStakeholderTableConfig";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import {
  getTopProjectStakeholderFormFields,
  getBottomProjectStakeholderFormFields,
  getProjectStGroupSearchField
} from "../../forms/projectStakeholder/ProjectStakeholder_data";
import { Enums } from "../../../../enums";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import I18n from "../../../../core/localization/I18n";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { IProjectStakeholderGroupsApi } from "../../../../services/api/v1/projectStakeholdersGroups/IProjectStakeholderGroups.api";

export type IStGroupStakeholder = FP.Entities.IStakeholder & {
  isInProject: boolean;
};

export class AddStakeholderGroupModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectId: number;
  orgId: number;
  localStorageService: ILocalStorageService;
  searchFormModel: SingleFormModel;
  stakeholderGroupProvider: IStakeholderGroupsApi;
  orgProvider: IOrganisationsApi;
  projectProvider: IProjectsApi;
  httpProgress: IHttpProgressModel;
  tableModel: ITableModel<IStGroupStakeholder>;
  @observable isLoading: boolean = true;
  @observable.ref projectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable.ref stakeholderGroup: FP.Entities.IStakeholderGroup;
  @observable.ref stakeholderGroupStakeholders: IStGroupStakeholder[];
  @observable.ref stakeholdersNotInProject: IStGroupStakeholder[];
  @observable.ref topProStakeholderFormModel: SingleFormModel;
  @observable.ref bottomProStakeholderFormModel: SingleFormModel;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  tagsProvider: ITagsApi;
  projectStakeholderGroupsProvider: IProjectStakeholderGroupsApi;

  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.projectId = parseInt(this.routeProps.match.params["projectId"]);
    this.stakeholderGroupProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderGroupsProvider = this.appService.getService<IProjectStakeholderGroupsApi>(Services.ProjectStakeholderGroupsApi);
    this.projectStakeholdersProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.setTable();
    this.setSearchFormModel();
    this.loadProjectStakeholders();
  }

  @action
  setTable = () => {
    this.tableModel = new TableModel();
    const tableConfig = GetCompactStakeholderTableConfig();
    this.tableModel.set(tableConfig);
  };

  @action
  setSearchFormModel = () => {
    this.searchFormModel = new SingleFormModel();
    this.searchFormModel.formFields = getProjectStGroupSearchField(
      this.orgId,
      this.orgProvider,
      this.loadStakeholderGroup,
      this.stakeholderGroupProvider
    );
  };

  @action
  loadProjectStakeholders = async () => {
    this.isLoading = true;

    const res = await this.projectStakeholdersProvider.getStakeholders(this.orgId, this.projectId);

    if (!res || res.isError) return;

    if (res.payload) {
      this.projectStakeholders = res.payload;
    }

    this.isLoading = false;
  };

  @action
  loadStakeholderGroup = async (stakeholderGroup: FP.Entities.IStakeholderGroup) => {
    this.stakeholderGroup = stakeholderGroup;

    this.httpProgress.showOverlay();
    const res = await this.stakeholderGroupProvider.getStakeholders(this.orgId, stakeholderGroup.id);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    if (res.payload) {
      const stakeholderGroupStakeholders = res.payload.map(stakeholder => {
        const foundStakeholder = this.projectStakeholders.find(
          pStakeholder => pStakeholder.stakeholder?.id === stakeholder.id
        );
        return {
          ...stakeholder,
          isInProject: foundStakeholder ? true : false
        };
      });

      this.stakeholderGroupStakeholders = stakeholderGroupStakeholders;
      this.tableModel.setData(stakeholderGroupStakeholders);

      this.stakeholdersNotInProject = this.stakeholderGroupStakeholders.filter(s => !s.isInProject);

      if (this.stakeholdersNotInProject.length > 0) {
        this.setProjectStakeholderFormModel();
      } else {
        this.resetProjectStakeholderFormModel();
      }
    }
  };

  @action
  setProjectStakeholderFormModel = () => {
    this.topProStakeholderFormModel = new SingleFormModel();
    this.topProStakeholderFormModel.formFields = getTopProjectStakeholderFormFields(this.orgId, this.projectId);

    this.bottomProStakeholderFormModel = new SingleFormModel();
    this.bottomProStakeholderFormModel.formFields = getBottomProjectStakeholderFormFields(
      this.orgId,
      this.projectId,
      this.tagsProvider
    );

    this.bottomProStakeholderFormModel.actions = [
      {
        id: "addToProject",
        label: I18n.t("phrases.addToProject"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          className: "ml-auto"
        },
        onAction: this.addProjectStakeholderGroup
      }
    ];
  };

  @action
  resetProjectStakeholderFormModel = () => {
    this.topProStakeholderFormModel = null;
    this.bottomProStakeholderFormModel = null;
  };

  addProjectStakeholderGroup = async () => {
    this.topProStakeholderFormModel.isSaving = true;
    this.bottomProStakeholderFormModel.isSaving = true;
    let topFormRes = await this.topProStakeholderFormModel.submit();
    let bottomFormRes = await this.bottomProStakeholderFormModel.submit();
    let formRes = { ...topFormRes, ...bottomFormRes };

    if (!formRes) return;

    formRes.stakeholderGroupId = this.stakeholderGroup.id;

    this.httpProgress.showOverlay();
    const res = await this.projectStakeholderGroupsProvider.addStakeholderGroup(
      this.orgId,
      formRes.projectId,
      formRes as FP.Entities.IStakeholderGroup
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    this.routeProps.history.push(`/organisations/${this.orgId}/projects/${this.projectId}/stakeholders`);
  };
}
