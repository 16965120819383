import * as React from "react";
import NotificationsModel from "./Notifications_model";
import { observer } from "mobx-react";
import { Panel } from "../../ui/Panel";
import { IF } from "../../hoc/If";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import moment from "moment";
import I18n from "../../../core/localization/I18n";
import { Animations } from "../../../core/util/Animations";
import { RenderXssSafeString } from "../../../core/AntiXss/AntiXssHelper";

interface INotificationsContentProps {
  model: NotificationsModel;
  className?: string;
}

const NotificationsContent: React.FunctionComponent<INotificationsContentProps> = observer(props => {
  return (
    <Panel.Panel
      hasBorderRadius={true}
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      className={`p-2 ml-3 ${Animations.FADE_IN} speed-3 ${props.className}`}
      style={{ width: "315px", height: "330px", overflow: "auto" }}
    >
      <IF condition={!props.model.isDataLoaded}>
        <PositionedSpinner />
      </IF>
      {props.model.isDataLoaded && props.model.latestNotifications.length === 0 ? (
        <p>{I18n.t("phrases.noNotifications")}</p>
      ) : (
        props.model.latestNotifications.map(notification => (
          <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="py-3 text-left">
            <h4>{moment(notification.createdAt).format("L")}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: RenderXssSafeString(notification.content)
              }}
            ></div>
          </Panel.Panel>
        ))
      )}
    </Panel.Panel>
  );
});

export default NotificationsContent;
