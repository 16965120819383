import { action, observable } from "mobx";

export class ProjectPermissionsModel {
    @observable showTour: boolean = false;

    constructor() {
        this.showTour = false;
    }

    @action
    toggleShowTour = () => {
        this.showTour = !this.showTour;
    };

    tourText = ():string => {
        return this.showTour ? "Hide the tour":"Take the tour";
    }
}