import {
  ITimelineGroup as TG,
  ITimelineItem as TI,
  ITimelineMarker as TM,
  TimeLineModel
} from "@flightpath/coreui/dist/widgets/timeline/Timeline_model";
import { TimelineView } from "@flightpath/coreui/dist/widgets/timeline/Timeline_view";
import { EditableTimelineModel } from "@flightpath/coreui/dist/widgets/timeline/EditableTimeline_model";
export interface ITimelineGroup extends TG {
}

export interface ITimelineItem extends TI {
}

export interface ITimelineMarker extends TM {
}

export { TimeLineModel, TimelineView, EditableTimelineModel }


export const TimelineStyles = {
  background: "#F7820D"
}