const DevConfig = {
  ENV: "development",
  API: {
    VERSION: {
      __LATEST__: "v1",
      __V2__: "v2"
    },
    URL: `${window.appConfig.flightPathCoreApiUrl}/`
  },
  LOGIN_URL: "https://identity.serendata.app"
};
const ProConfig = {
  ENV: "production",
  API: {
    VERSION: {
      __LATEST__: "v1",
      __V2__: "v2"
    },
    URL: `${window.appConfig.flightPathCoreApiUrl}/`
  },
  LOGIN_URL: "https://identity.serendata.app"
};

declare var process;

function Config() {
  return process?.env?.NODE_ENV === "development" ? DevConfig : ProConfig;
}

export default Config();
