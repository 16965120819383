import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IReportsApi } from "./IReports.api";
import { IHttp } from "../../IHttp";
import { AxiosRequestConfig } from "axios";
import { BaseProjectApiModel } from "../../BaseProjectApiModel";

export class ReportsApi extends BaseProjectApiModel<any> implements IReportsApi {
  controller: string = "reports";
  minorController: string = "projects";
  constructor(http: IHttp) {
    super(http, "reports");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  //#region Stakeholder heat map

  getStHeatMapData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/stakeholderHeatMap`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
    console.error("API error - Status Code: " + res.status + "\t Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  //#endregion

  //#region impact level
  getImpactLevelData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/impactLevel`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  };

  //#endregion

  //#region action burndown

  getActionBurnDownData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/actionBurndown`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  };

  //#endregion

  //#region action over time

  getActionOverTimeData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/actionOverTime`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  };

  //#endregion

  //#region impact over time

  getImpactOverTimeData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/impactOverTime`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  };

  startProjectArtifactsReporting = async (
    organisationId: number,
    projectId,
    config?: AxiosRequestConfig
  ):Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.http.url}/${this.version}/hubs/organisations/${organisationId}/projects/${projectId}/start-project-artifacts-reporting`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  }

  //#endregion
}
