import * as React from "react";
import { ITableRowModel } from "./ITableRowModel";
import Table from "../../components/ui/Table";
import DivTable from "../../components/ui/Table/DivTable";
import { observer } from "mobx-react";
import { Checkbox } from "../../components/ui/Checkbox";
import { ITableHeaderModel } from "./ITableHeaderModel";
import { GetDeepContent } from "../util/Helpers";
import { Tooltip } from "react-tippy";
import { Icon, IconSymbols } from "../../components/ui/Icon";
import { Panel } from "../../components/ui/Panel";
import { Accordion } from "../../components/ui/Accordion";
import * as _ from "lodash";

export interface TableRowProps extends React.HTMLProps<HTMLElement> {
  model: ITableRowModel;
}

@observer
export class TableRowView extends React.Component<TableRowProps, any> {
  getRowColumns = (row: ITableRowModel, headers: ITableHeaderModel[]) => {
    let col = [];
    let { model } = this.props;
    let headerCols = headers.slice(0);
    let T = model.isDivTable ? DivTable : Table;
    if (model.isBulkMode) {
      col.push(
        <T.Col key={model.index + 0}>
          <Checkbox
            className="mr-3"
            onChange={e => {
              model.setIsChecked(e.currentTarget.checked);
            }}
            checked={model.isChecked}
          />
        </T.Col>
      );
      headerCols.shift();
    }

    let s = headerCols.map((col: ITableHeaderModel, k) => {
      return (
        <T.Col
          key={"row-" + model.index + "-" + k}
          className={`${col.config.key === "actions" ? "table-cell--action" : ""} ${col.config.cellClassName || ""} ${
            model.isDivTable
              ? "divTableCell--" + _.kebabCase(col.config.key)
              : "tableCell--" + _.kebabCase(col.config.key)
          }`}
          onClick={() => {
            if (col.config.key !== "actions") {
              model.onRowClick && model.onRowClick(row);
            }
          }}
        >
          {this.getCellContent(col, row.rowObject)}
        </T.Col>
      );
    });
    col = col.concat(s);

    return model.isAccordion ? <div className="divTableRow__accordion-header">{col}</div> : col;
  };

  getCellContent = (col: ITableHeaderModel, row: any): React.ReactNode => {
    if (col.config.key === "actions") {
      return (
        <Tooltip
          className="table__tooltip"
          trigger="click"
          interactive={true}
          position="left"
          html={
            <Panel.Panel
              className="table__tooltip-content"
              style={{ width: "110px" }}
              background={Panel.PanelBackgrounds.BG_WHITE}
              hasShadow={true}
            >
              {this.props.model.getActionComponents()}
            </Panel.Panel>
          }
        >
          <Icon symbol={IconSymbols.DotsHorizontal} />
        </Tooltip>
      );
    }

    if (col.config.cellContentFunction) {
      return col.config.cellContentFunction(row, row.index);
    }

    if (!col.config.selector) {
      return row[col.config.key];
    }

    if (typeof col.config.selector === "function") {
      let cb = col.config.selector as (row: any) => React.ReactNode;
      return cb(row);
    }

    let selector: string = col.config.selector;
    let tree = selector.split(".");
    if (tree.length > 1) {
      let s = GetDeepContent({ ...row }, col.config.selector);
      return s;
    }

    return row[col.config.selector];
  };

  render() {
    let { model } = this.props;
    let T = model.isDivTable ? DivTable : Table;
    const renderedAccordionContent = model.renderExpandContent && model.renderExpandContent(model);
    if (model.isAccordion && renderedAccordionContent) {
      return (
        <T.Row
          {...this.props}
          className={`${model.className} ${model.index % 2 === 0 ? "table__row--odd" : "table__row--even"}`}
        >
          <Accordion
            isDynamicallyLoaded={true}
            side="left"
            actions={null}
            isArrowInteractiveOnly={true}
            header={this.getRowColumns(model, model.headerCol)}
            onClick={() => window.dispatchEvent(new Event("resize"))}
          >
            {renderedAccordionContent}
          </Accordion>
        </T.Row>
      );
    }

    return (
      <T.Row
        {...this.props}
        className={`${model.className} ${model.index % 2 === 0 ? "table__row--odd" : "table__row--even"}`}
      >
        {this.getRowColumns(model, model.headerCol)}
      </T.Row>
    );
  }
}
