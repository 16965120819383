import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";
import { IHttp } from "../../IHttp";
import { IProjectStakeholderGroupsApi } from "./IProjectStakeholderGroups.api";

export class ProjectStakeholderGroupsApi
  extends ExportableProjectApi<FP.Entities.IProjectStakeholder>
  implements IProjectStakeholderGroupsApi {

  controller: string = "projects"

  constructor(http: IHttp) {
    super(http, "stakeholders");
    this.url = `${this.http.url}/${this.version}/organisations`
  }

  removeStakeholderGroup = async (
    organisationId: number,
    projectId: number,
    stGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholderGroups/${stGroupId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  addStakeholderGroup = async (
    organisationId: number,
    projectId: number,
    stakeholder: FP.Entities.IStakeholderGroup,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholderGroups`;
    let res = await this.http.post(url, stakeholder, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>;
    }
  };

  getStakeholderGroups = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholderGroups`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>;
    }
  };


}