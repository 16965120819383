import * as React from "react";
import { ITableModel, ITableConfig } from "./ITableModel";
import { BaseModel } from "../util/BaseModel";
import { observable, action } from "mobx";
import { TableView } from "./Table_view";
import { Checkbox } from "../../components/ui/Checkbox";
import { ITableRowModel } from "./ITableRowModel";
import { TableRowModel } from "./TableRow_model";
import { ITableAction } from "./ITableAction";
import { IPaginatableModel } from "../paginatedContent/IPaginatedContentModel";
import { ITableHeaderConfig, ITableHeaderModel } from "./ITableHeaderModel";
import { TableHeaderModel } from "./TableHeader_model";

export class TableModel<T> extends BaseModel implements ITableModel<T>, IPaginatableModel {
  @observable isLoading: boolean = true;
  @observable isBulkMode: boolean = false;
  @observable.ref colHeaders: ITableHeaderModel[];
  @observable.ref actions: ITableAction[] = [];
  actionsFn: (rowObject: T) => ITableAction[];
  @observable.ref data: ITableRowModel[];
  @observable.ref config: ITableConfig<T> = {
    initData: [],
    tableProps: null,
    colHeaders: [],
    actions: [],
    actionsFn: null,
    isDivTable: false,
    onRowClick: null,
    height: null,
    renderExpandContent: null
  };

  constructor(initOpts?: ITableConfig<T>) {
    super();
    if (initOpts) {
      this.set(initOpts);
    }
  }

  @action
  setBulkMode = (isBulkMode: boolean) => {
    this.isBulkMode = isBulkMode;
    this.data.forEach(e => {
      e.isBulkMode = isBulkMode;
    });
    if (this.isBulkMode) {
      this.colHeaders.unshift(
        new TableHeaderModel({
          key: "checkbox",
          content: (
            <Checkbox
              className="table__input mr-3"
              onClick={(ev: React.SyntheticEvent<any>) => {
                this.data &&
                  this.data.forEach(e => {
                    e.isChecked = ev.currentTarget.checked;
                  });
              }}
            />
          ),
          selector: "checkbox"
        } as any)
      );
    } else {
      this.colHeaders.shift();
    }
  };

  @action.bound
  set = (opt: ITableConfig<T>) => {
    this.config = {
      colHeaders: opt.colHeaders || this.config.colHeaders,
      initData: opt.initData || this.config.initData,
      tableProps: opt.tableProps || this.config.tableProps,
      actions: opt.actions || this.config.actions,
      actionsFn: opt.actionsFn || this.config.actionsFn,
      isDivTable:
        typeof opt.renderExpandContent !== "undefined" ||
        opt.renderExpandContent !== null ||
        opt.isDivTable ||
        this.config.isDivTable,
      height: opt.height || this.config.height,
      onRowClick: opt.onRowClick || this.config.onRowClick,
      renderExpandContent: opt.renderExpandContent || this.config.renderExpandContent
    };
    this.colHeaders = this.generateTableHeaders(this.config.colHeaders);
    this.actions = this.config.actions;
    this.actionsFn = this.config.actionsFn;

    if (this.actions.length > 0 || this.actionsFn) {
      this._addActionColumn();
    }
  };

  getSelectedRows = (): ITableRowModel[] => {
    if (!this.isBulkMode) {
      return [];
    }
    return this.data.filter(e => e.isChecked);
  };

  generateTableHeaders = (colHeaders: ITableHeaderConfig[]) => {
    return colHeaders.map(e => new TableHeaderModel(e));
  };

  generateTableRowModels = (data: T[]): ITableRowModel[] => {
    return data?.map((e, idx) => {
      return new TableRowModel({
        rowObject: e,
        index: idx,
        headerCol: this.colHeaders,
        isBulkMode: this.isBulkMode,
        actions: this.actionsFn ? this.actionsFn(e) : this.actions,
        isDivTable: this.config.isDivTable,
        renderExpandContent: this.config.renderExpandContent,
        onRowClick: this.config.onRowClick
      });
    });
  };

  _addActionColumn = () => {
    this.colHeaders.push(
      new TableHeaderModel({
        key: "actions",
        content: "",
        onClick: () => {
          this.data.forEach(e => {
            e.isChecked = true;
          });
        },
        selector: "actions",
        isDivTable: this.config.isDivTable
      } as any)
    );
  };

  @action.bound
  setData = (data: T[]) => {
    this.config.initData = data;

    this.data = this.generateTableRowModels(data);
    this.isLoading = false;
  };

  renderComponent = () => {
    return <TableView model={this} />;
  };
}
