import * as _ from "lodash";
import { action, observable } from "mobx";
import { SettingType } from "../../enums";
import { IOrganisationSetting } from "./OrganisationSettingsTypes";

export interface IOrganisationSettingsContextModel {
  updateFromHeaders: (version: string, data: string) => void;
  getSettingValue: (setting: SettingType) => any;
  getSetting: (setting: SettingType) => IOrganisationSetting;
  isTagEnabled: () => boolean;
  isCustomPropertiesEnabled: () => boolean;
  isVisualisationsEnabled: () => boolean;
}

export class OrganisationSettingsContextModel implements IOrganisationSettingsContextModel {
  settings: Dictionary<IOrganisationSetting>;
  @observable version: string = "";

  updateFromHeaders = (version: string, data: string) => {
    if (this.version === version || typeof data === "undefined" || typeof version === "undefined") return;

    this.setVersion(version);
    this.settings = this.mapToSettings(JSON.parse(data));
  };

  @action
  setVersion = (version: string) => {
    this.version = version;
  };

  mapToSettings = (data: any[]): Dictionary<IOrganisationSetting> => {
    let res = _.map(data, e => {
      return {
        setting: e.t as SettingType,
        value: e.v
      };
    });
    return _.keyBy(res, "setting");
  };

  getSettingValue = (setting: SettingType) => {
    const val = this.getSetting(setting);
    if (!val) return;
    return val.value;
  };

  getSetting = (setting: SettingType) => {
    if (!this.settings) return;
    return this.settings[setting];
  };

  isTagEnabled = () => {
    return this.getSettingValue(SettingType.TAGS_ENABLED) === "1";
  };

  isCustomPropertiesEnabled = () => {
    return this.getSettingValue(SettingType.CUSTOM_PROPERTIES_ENABLED) === "1";
  };

  isVisualisationsEnabled = () => {
    return this.getSettingValue(SettingType.VISUALISATIONS_ENABLED) === "1";
  };
}
