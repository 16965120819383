import { BaseApiModel, IFlightPathApiResponse } from "../../BaseApiModel";
import { IHttp } from "../../IHttp";
import { IAuthApi } from "./IAuth.api";

export class AuthApi extends BaseApiModel<any> implements IAuthApi {
  controller: string = "";

  constructor(http: IHttp) {
    super(http, "");
    this.url = `${this.http.url}/${this.version}/auth`;
  }

  getUserClaims = async (organisationId: number) => {
    let url = `${this.url}/claims/organisations/${organisationId || 0}`;
    const res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  createSystemUser = async () => {
    let url = `${this.url}/add`;
    const res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };
}
