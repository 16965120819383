import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import {
  getTopProjectStakeholderFormFields,
  getBottomProjectStakeholderFormFields
} from "../../forms/projectStakeholder/ProjectStakeholder_data";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { IModalService } from "../../../../core/modal/IModalService";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  SaveAndReviewModalTitle
} from "../../../../components/ui/ReviewModal";

export class EditStakeholderViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectProvider: IProjectsApi;
  projectId: number;
  stakeholderId: number;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref stakeholder: FP.Entities.IStakeholder;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref topProStakeholderFormModel: SingleFormModel;
  @observable.ref bottomProStakeholderFormModel: SingleFormModel;
  projectStakeholderProvider: IProjectStakeholdersApi;
  organisationId: number;
  returnUrl: string;
  tagsProvider: ITagsApi;
  confirmationService: IModalService;
  @observable reviewCommentInput: string;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.confirmationService = appService.getService<IModalService>(Services.ConfirmationService);
    this.organisationId = parseInt(this.routeProps.match.params["organisationId"]);
    this.projectId = parseInt(this.routeProps.match.params["projectId"]);
    this.stakeholderId = parseInt(this.routeProps.match.params["stakeholderId"]);
    this.loadProjectStakeholder(this.projectId, this.stakeholderId);
    const queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);

    // check if there is a previous url so it redirects with the previous url in the string params
    const prevUrl = queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");

    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/${this.stakeholderId}${
        prevUrl ? "?" + QUERY_STRING_PARAMS.RETURN_URL + "=" + encodeURIComponent(prevUrl) : ""
      }`
    );
  }

  onMount = () => {};

  onUnmount = () => {};

  @action
  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    const res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);
    if (!res || res.isError) return;
    this.projectStakeholder = res.payload;
    this.stakeholder = this.projectStakeholder.stakeholder;
    this.setForm();
    this.isLoading = false;
  };

  @action
  setForm = () => {
    this.topProStakeholderFormModel = new SingleFormModel();
    this.topProStakeholderFormModel.formFields = getTopProjectStakeholderFormFields(
      this.organisationId,
      this.projectId,
      this.stakeholder,
      this.projectStakeholder
    );

    this.bottomProStakeholderFormModel = new SingleFormModel();
    this.bottomProStakeholderFormModel.formFields = getBottomProjectStakeholderFormFields(
      this.organisationId,
      this.projectId,
      this.tagsProvider,
      this.stakeholder,
      this.projectStakeholder
    );

    this.bottomProStakeholderFormModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto",
          href: this.returnUrl
        }
      },
      {
        id: "save",
        label: I18n.t("phrases.save"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-2"
        },
        onAction: this.updateProjectStakeholder
      },
      {
        id: "UpdateAndReviewActionButton",
        label: I18n.t("phrases.saveAndReview"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        },
        onAction: this.showReviewModal
      }
    ];
  };

  @action
  updateProjectStakeholder = async () => {
    this.topProStakeholderFormModel.isSaving = true;
    this.bottomProStakeholderFormModel.isSaving = true;
    let topRes = await this.topProStakeholderFormModel.submit();
    let bottomRes = await this.bottomProStakeholderFormModel.submit();
    let res = { ...topRes, ...bottomRes };
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.projectStakeholderProvider.update(
      this.organisationId,
      this.projectId,
      this.stakeholderId,
      res as FP.Entities.IProjectStakeholder
    );
    this.httpProgress.hideOverlay();
    if (!result || result.isError) return;
    this.projectStakeholder = result.payload;
    this.routeProps.history.push(this.returnUrl);
  };

  updateStakeholderAndMarkAsReviewed() {
    // Set mark as reviewed
    const markAsReviewedField = this.bottomProStakeholderFormModel.formFields.find(
      field => field.key === "markAsReviewed"
    );

    if (markAsReviewedField) {
      markAsReviewedField.value = true;
    }

    // Set the review notes
    const reviewedNotes = this.bottomProStakeholderFormModel.formFields.find(field => field.key === "reviewNotes");

    if (reviewedNotes && this.reviewCommentInput) {
      reviewedNotes.value = this.reviewCommentInput;
    }

    // Hide the modal and save the form
    this.confirmationService.hide();
    this.updateProjectStakeholder();
  }

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showReviewModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <SaveAndReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("forms.confirmSaveAndReview"),
        I18n.t("forms.cancelSaveAndReview"),
        ReviewModalComponentProps,
        async () => {
          await this.updateStakeholderAndMarkAsReviewed();
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };
}
