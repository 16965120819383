import React from "react";
import { Panel } from "../../components/ui/Panel";

interface PermissionErrorMessageProps {}

export const PermissionErrorMessage: React.FC<PermissionErrorMessageProps> = props => {
  return (
    <Panel.Panel type={Panel.PanelTypes.OUTLINES} className="p-2" hasBorderRadius={true}>
      {props.children}
    </Panel.Panel>
  );
};
