import React, { useEffect } from "react";
import { StakeholderConcernsViewModel } from "./StakeholderConcernsView_model";
import { Button, ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { ConcernCard } from "../../concerns/ConcernCard";
import I18n from "../../../../core/localization/I18n";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Enums } from "../../../../enums";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { Animations } from "../../../../core/util/Animations";
import { Panel } from "../../../../components/ui/Panel";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export interface StakeholderConcernsViewProps {
  model: StakeholderConcernsViewModel;
}

const StakeholderConcernsView: React.FunctionComponent<StakeholderConcernsViewProps> = observer(({ model }) => {
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`pt-5 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
              <Button onClick={() => model.showAddConcernModal()}>
                <Icon symbol={IconSymbols.Plus} className="mr-2" />
                {I18n.t("phrases.addKeyConcern")}
              </Button>
            </CanEdit>

            <ButtonIcon
              className="float-right"
              symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              type={ButtonTypes.OUTLINE_PRIMARY}
              onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
            />

            <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
              <Input
                autoFocus={true}
                size={-1}
                onChange={model.updateSearchValue}
                borderStyle="underline"
                placeholder={I18n.t("placeholders.searchStakeholderConcerns")}
                icon="search"
                value={model.searchValue}
                onBlur={null}
                className="mt-3"
              />
            </AnimateHeight>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            {model.filteredConcerns.length ? (
              model.filteredConcerns.map(concern => {
                return <ConcernCard key={concern.id} item={concern} actions={model.concernCardActions} />;
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                <div>{I18n.t("phrases.noStakeholderConcerns")}</div>
              </Panel.Panel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { StakeholderConcernsView };
