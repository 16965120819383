import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CreateProgrammeViewModel } from "./CreateProgrammeView_model";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../core/localization/I18n";
import { Panel } from "../../../../components/ui/Panel";

export interface CreateProgrammeViewProps {
  model: CreateProgrammeViewModel;
}

const CreateProgrammeView: React.FunctionComponent<CreateProgrammeViewProps> = observer(props => {
  const { model } = props;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  });

  return (
    <div data-testid="create-programme-view" className="create-programme-view container-fluid pt-8">
      <div className="row mb-4">
        <div className="col-lg-7">
          <h1>
            {I18n.t("phrases.createNew")} {I18n.t("entities.programme")}
          </h1>
          <p>{I18n.t("phrases.createProgrammeDescription")}</p>
        </div>
      </div>
      <div className="col-6">

        <Panel.Panel
          hasBorderRadius={true}
          type={Panel.PanelTypes.OUTLINES}
          className="p-4 mb-4"
        >
          <div className="row">
            <div className="col-12">
              <SingleForm
                model={model.formModel} />
            </div>
          </div>
        </Panel.Panel>
      </div>
    </div>
  );
});

export { CreateProgrammeView };
