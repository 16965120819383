import { startUp } from "./start";
import { AppService } from "strikejs-app-service";

const bootstrap = () =>
  startUp({
    isDebug: true,
    configureServices(appService: AppService) {},
    configureApp(app) {}
  });

export default bootstrap;
