import * as React from 'react';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import { IAutocompleteModel } from '../../../../core/forms/controls/autocomplete/IAutocompleteModel';
import { INIT_AUTOCOMPLETE } from '../../../../core/forms/controls/autocomplete/Autocomplete_init';
import { AutocompleteOption } from '../../../../components/ui/_forms/Autocomplete/AutocompleteOption';
import { IListingModel } from '../../../../core/forms/controls/listing/IListingModel';
import { INIT_LISTING_FIELD } from '../../../../core/forms/controls/listing/Listing_model';
import { AutocompleteModel } from '../../../../core/forms/controls/autocomplete/Autocomplete_model';
import I18n from '../../../../core/localization/I18n';
import _ from 'lodash';
import { IOrganisationsApi } from '../../../../services/api/v1/organisations/IOrganisations.api';
import { FORM_COL } from '../../../../constants';
import { IconSymbols } from '../../../../components/ui/Icon';

export const getImpactStakeholderGroupFormFields = (
    organisationProvider: IOrganisationsApi,
    organisationId: number) => {



    let stakeholderGroupsSearch: Partial<IAutocompleteModel> = {
        ...INIT_AUTOCOMPLETE,
        key: "stakeholderGroupsSearch",
        label: (<label htmlFor={"stakeholderGroupsSearch"}>{I18n.t("forms.impactStakeholderGroups")}</label>),
        placeholder: I18n.t("placeholders.searchStakeholderGroup"),
        optionElement: (<AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={(e) => e.name}
        />),
        componentProps: {
            className: "form-control",
            icon: IconSymbols.Search,
        },
        onFocus: async function () {
            let self: IAutocompleteModel = this;
            this.componentProps.disabled = false;
            const res = await organisationProvider.getStakeholderGroups(organisationId);

            if (res?.payload) {
                const sortedStakeholderGroups = _.orderBy(res.payload, [stakeholderGroup => stakeholderGroup.name.toLowerCase()]);
                self.setOptions(sortedStakeholderGroups);
            }
        },
        subscribeTo: ["stakeholderGroups"],
        onItemSelected: async function () {
            let self: AutocompleteModel = this;
            let listingModel: IListingModel = self.channels.stakeholderGroups as IListingModel;
            let val = self.value
            listingModel.addItem(val);
            self.searchQuery = ""
        },
        shouldClearOnBlur: true,
        searchAttribute: "name",
        fieldClassName: FORM_COL.FULL_WIDTH,
        valueLabelFn: (e) => e.name
    }


    let listing: Partial<IListingModel> = {
        ...INIT_LISTING_FIELD,
        key: "stakeholderGroups",
        placeholder: I18n.t("placeholders.selectStakeholderGroups"),
        label: (<label htmlFor={"stakeholderGroups"}>{I18n.t("forms.selectedStakeholderGroups")}</label>),
        fieldClassName: FORM_COL.FULL_WIDTH,
        extractValue: function () {
            return this.value && this.value.map((e) => e.id)
        },
        selector: (e) => <p className="mb-0 d-inline-block">{e.name}</p>

    }

    const fields = [];

    fields.push(stakeholderGroupsSearch);
    fields.push(listing);
    const models = generateFormFieldsFromJson(fields);
    return models;
}