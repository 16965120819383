import * as React from 'react';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import { IAutocompleteModel } from '../../../../core/forms/controls/autocomplete/IAutocompleteModel';
import { INIT_AUTOCOMPLETE } from '../../../../core/forms/controls/autocomplete/Autocomplete_init';
import { AutocompleteOption } from '../../../../components/ui/_forms/Autocomplete/AutocompleteOption';
import { IListingModel } from '../../../../core/forms/controls/listing/IListingModel';
import { INIT_LISTING_FIELD, ListingModel } from '../../../../core/forms/controls/listing/Listing_model';
import { AutocompleteModel } from '../../../../core/forms/controls/autocomplete/Autocomplete_model';
import { IImpactsApi } from '../../../../services/api/v1/impacts/IImpacts.api';
import _ from 'lodash';
import { Enums } from '../../../../enums';
import I18n from '../../../../core/localization/I18n';
import { AutocompletePerson } from '../../../../components/ui/AutocompletePersonOption';
import { FORM_COL } from '../../../../constants';
import { IconSymbols } from '../../../../components/ui/Icon';

export const getImpactStakeholderFormFields = (
    impactProvider: IImpactsApi,
    impact: FP.Entities.IImpact,
    orgId: number
) => {


    let stakeholdersSearch: Partial<IAutocompleteModel> = {
        ...INIT_AUTOCOMPLETE,
        key: "stakeholdersSearch",
        label: (<label htmlFor={"stakeholdersSearch"}>{I18n.t("forms.impactStakeholders")}</label>),
        placeholder: I18n.t("placeholders.searchStakeholder"),
        optionElement: (<AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={(e) => { return <AutocompletePerson {...e} /> }}
        />),
        componentProps: {
            className: "form-control",
            icon: IconSymbols.Search,
        },
        charInputNumber: 1,
        onFocus: async function () {
            let self: IAutocompleteModel = this;
            this.componentProps.disabled = false;
            const res = await impactProvider.getUnassignedStakeholders(orgId, impact.projectId, impact.id);

            if (res?.payload) {
                const sortedStakeholders = _.orderBy(res.payload, [
                    projectStakeholder => projectStakeholder.stakeholder.firstName.toLowerCase(),
                    projectStakeholder => projectStakeholder.stakeholder.lastName.toLowerCase()
                ])
                self.setOptions(sortedStakeholders.map(e => {
                    return {
                        ...e.stakeholder,

                        // impact is storing impact stakeholders though a 
                        // here we need to assign projectStakeholder relationship
                        // a projectStakeholderId so that we can retrieve that 
                        // in the listing component
                        projectStakeholderId: e.id
                    }
                }));
            }
        },
        subscribeTo: ["stakeholders"],
        onItemSelected: async function () {
            let self: AutocompleteModel = this;
            let listingModel: ListingModel = self.channels.stakeholders as ListingModel;
            let val = self.value
            listingModel.addItem(val);
            self.searchQuery = ""
        },
        shouldClearOnBlur: true,
        fieldClassName: FORM_COL.FULL_WIDTH,
        valueLabelFn: (e) => e.firstName + " " + e.lastName,
        filterFn: (items, query) => {
            const lowerQuery = query.toLowerCase();
            return _.filter(items, (item: FP.Entities.IStakeholder) => {
                const lowerName = item.stakeholderType === Enums.StakeholderType.AUDIENCE ?
                    item.firstName.toLowerCase() : `${item.firstName} ${item.lastName}`.toLowerCase();
                const lowerEmail = item.email.toLowerCase();
                return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
            })
        },
    }


    let listing: Partial<IListingModel> = {
        ...INIT_LISTING_FIELD,
        key: "stakeholders",
        placeholder: I18n.t("placeholders.selectStakeholder"),
        label: (<label htmlFor={"stakeholders"}>{I18n.t("forms.selectedStakeholders")}</label>),
        fieldClassName: FORM_COL.FULL_WIDTH,
        extractValue: function () {
            return this.value && this.value.map((e) => e.projectStakeholderId)
        },
        selector: (pst: FP.Entities.IStakeholder) => {
            let e = pst;
            return <p className="mb-0 d-inline-block">

                {
                    e.stakeholderType === Enums.StakeholderType.AUDIENCE ?
                        e.firstName
                        :
                        `${e.firstName} ${e.lastName} (${e.email})`
                }
            </p>
        },
    }

    const fields = [];

    fields.push(stakeholdersSearch);
    fields.push(listing);
    const models = generateFormFieldsFromJson(fields);
    return models;
}