import { IModalModel } from "./IModalModel";
import { observable, action, computed } from "mobx";
import { IModalAction } from "./IModalAction";
import { BaseModel } from "../util/BaseModel";
import { IModalConfig, IModalService } from "./IModalService";
import { ModalProps } from "../../components/ui/Modal";
import * as React from "react";
import { Animations } from "../util/Animations";

export class ModalModel extends BaseModel implements IModalModel {
  progress: number;
  @observable type: string = "default";
  service?: IModalService;
  onAction?(action: IModalAction, service: IModalService, model: IModalModel): void {
    throw new Error("Method not implemented.");
  }

  private userOnClose: () => void;
  private res: (v: any) => void;
  private rej: (a: any) => void;

  @observable disableBackdrop: boolean = true;
  @observable pending: number = 0;
  @observable showClose: boolean = true;
  @observable footerClassName: string = "";
  @observable title: React.ReactNode = "";
  @observable className?: string = "";
  @observable.ref componentProps: Partial<ModalProps> = null;
  @observable actions: IModalAction[] = [];
  @observable.ref content: React.ReactNode = null;
  @observable visible: boolean = false;
  @observable animationOptions: FP.Generic.IAnimationOptions = {
    animateIn: Animations.FADE_IN,
    animateOut: Animations.FADE_OUT,
    speed: 6,
    delay: 0
  };
  @observable hasTitleSeparator?: boolean = false;
  readonly backdropAnimateIn: Animations = Animations.FADE_IN;
  readonly backdropAnimateOut: Animations = Animations.FADE_OUT;

  @observable _animationClass: string = this.animationOptions.animateIn;

  @computed get animationClass() {
    return `${this._animationClass} delay-${this.animationOptions.delay} speed-${this.animationOptions.speed}`;
  }

  @computed get isLoading() {
    return this.pending > 0;
  }

  constructor(service?: IModalService) {
    super();
    this.service = service || null;
  }

  @action.bound
  set(config: IModalConfig, res?: (v: any) => void, rej?: (a: any) => void) {
    this.content = config.content || null;
    this.actions = config.actions || [];
    this.componentProps = config.componentProps || null;
    this.className = config.className || "";
    this.showClose = typeof config.showClose !== "undefined" ? config.showClose : this.showClose;
    this.title = config.title || "";
    this.hasTitleSeparator = config.hasTitleSeparator || false;
    this.userOnClose = config.onClose;
    this.res = res;
    this.footerClassName = config.footerClassName || "";
    this.rej = rej;
    this.visible = true;
    this.animationOptions = { ...this.animationOptions, ...config.animationOptions };
    if (config.animationOptions) {
      this._animationClass = config.animationOptions.animateIn || this.animationOptions.animateIn;
    }
  }

  @action.bound
  animateInFn = () => {
    this._animationClass = this.animationOptions.animateIn;
  };

  @action.bound
  animateOutFn = () => {
    this._animationClass = this.animationOptions.animateOut;
  };

  @action.bound
  onClose(): void {
    this.service && this.service.hide();
    if (this.userOnClose) {
      this.userOnClose();
    } else if (this.rej) {
      this.rej(null);
    }
  }
}
