import React from "react";
import { FormFieldModel } from "../../formField/FormField_model";
import { ISliderFieldModel } from "./ISliderFieldModel";
import { Slider, SliderProps } from "../../../../components/ui/_forms/Slider";
import { action, observable } from "mobx";
import { FormFieldType } from "../../formField/FormFieldTypes";


export class SliderModel extends FormFieldModel<number, SliderProps> implements ISliderFieldModel {
  @observable value: number = null;
  readonly type: FormFieldType = FormFieldType.Slider;

  constructor(initOpts?: SliderModel) {
    super(initOpts);
    this.defaultValue = initOpts.defaultValue || 1;
    if (initOpts) {
      this.value = initOpts.value || (initOpts.componentProps.defaultValue as number) || this.value;
      this.validate = initOpts.validate || this.validate;
    }
  }

  @action setValue = (value: number): void => {
    this.value = value;
    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  };

  setFieldValue = (value: number) => {
    throw new Error("Method not implemented.");
  };

  extractValue() {
    return this.value;
  }

  validate: (...args: any[]) => any;

  @action
  reset = (): void => {
    this.hasChanges = true;
    this.errorMessage = null;
    this.value = this.defaultValue || 1;
  };

  renderComponent = () => {
    return (
      <Slider
        {...this.componentProps}
        value={this.value}
        // @ts-ignore
        onChange={(ev, value) => {
          this.setValue(value as number);
        }}
      />
    );
  };
}
