import { BaseModel, IModel } from "../util/BaseModel";
import { observable, action } from "mobx";
import { ReactNode } from "react";

export interface IHttpProgressModel extends IModel {
  isOverlayVisible: boolean;
  isProgressVisible: boolean;
  isTopProgressBarVisible: boolean;
  showOverlay: () => void;
  hideOverlay: () => void;
  showProgress: () => void;
  showHideProgress: () => void;
  showTopProgressBarVisible: () => void;
  hideTopProgressBarVisible: () => void;
}

export class HttpProgressModel extends BaseModel implements IHttpProgressModel {
  renderComponent?: () => ReactNode;
  @observable isOverlayVisible: boolean = false;
  @observable isProgressVisible: boolean = false;
  @observable isTopProgressBarVisible: boolean = false;

  @action
  showOverlay = () => {
    this.isOverlayVisible = true;
  };

  @action
  hideOverlay = () => {
    this.isOverlayVisible = false;
  };

  @action
  showProgress = () => {
    this.isProgressVisible = true;
  };

  @action
  showHideProgress = () => {
    this.isProgressVisible = false;
  };

  showTopProgressBarVisible = () => {
    this.setIsTopProgressBarVisible(true);
  };
  hideTopProgressBarVisible = () => {
    this.setIsTopProgressBarVisible(false);
  };

  @action
  setIsTopProgressBarVisible = val => {
    this.isTopProgressBarVisible = val;
  };
}
