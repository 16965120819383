import { BaseModel } from "../../../core/util/BaseModel";
import { UiSizes } from "../../../enums";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { PanelProps } from "../../ui/Panel";

export interface ReportCardConfig {
  key: string;
  size?: UiSizes;
  className?: string;
  title: React.ReactNode;
  loadData: (cardModel: ReportCardModel) => void;
  render: (data: object[]) => React.ReactNode;
  actions?: IUiAction<any>[];
  panelProps?: PanelProps;
}

export class ReportCardModel extends BaseModel {
  @observable.ref config: ReportCardConfig | null = null;
  @observable.ref data: any[] = [];
  @observable isDataLoaded: boolean = false;
  /**
   *
   */
  constructor(appService: AppService, initOpts: ReportCardConfig) {
    super();

    this.setConfig(initOpts);
    if (this.config) {
      this.config.loadData(this) /** WHAT IS THIS */;
    }
  }

  @action
  setConfig = (config: Partial<ReportCardConfig>) => {
    this.config = { ...this.config, ...config } as any;
  };

  setData = (data: object[]) => {
    this.data = data.slice();
    this.isDataLoaded = true;
  };

  onMount = () => { };

  onUnmount = () => { };
}
