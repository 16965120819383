import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { ContainerProps } from ".";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { UserView } from "../../pages/change/users/view/View_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { SettingsView } from "../../pages/change/users/settingsView/SettingsView_view";
import { SettingsViewModel } from "../../pages/change/users/settingsView/SettingsView_model";
import { useFlightPathUser } from "../../setup";
import { useRefreshClaims } from "../../core/auth/authorise";

export interface IUsersContainer extends ContainerProps { }

const UsersContainer: React.FunctionComponent<IUsersContainer> = ({ appService }) => {
  const user = useFlightPathUser();
  const refreshClaims = useRefreshClaims();
  return (
    <Switch>
      <Route
        path="/organisations/:organisationId/users/settings"
        render={props => (
          <>
            <PageTitle title={I18n.t(`phrases.user`)} />
            <SettingsView model={new SettingsViewModel(appService, props, user, refreshClaims)} />
          </>
        )}
      />
      <Route path="/organisations/:organisationId/users/:userId">
        <>
          <PageTitle title={I18n.t(`phrases.user`)} />
          <UserView />
        </>
      </Route>
      <Route path="*">
        <>
          <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
          <NotFoundIndex />
        </>
      </Route>
    </Switch>
  );
};

export { UsersContainer };
