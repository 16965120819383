import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action, runInAction } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { Services } from "../../../../constants";
import { getActionFormFields } from "../../forms/action/ActionFormSection_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Enums } from "../../../../enums";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { appHistory } from "../../../../setup";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { IModalService } from "../../../../core/modal/IModalService";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  SaveAndReviewModalTitle
} from "../../../../components/ui/ReviewModal";
import { IProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";

interface IEditActionViewModelOptions {
  appService: AppService;
  actionId: number;
  projectId: number;
  user: FP.Entities.IUser;
}

export class EditActionViewModel extends BaseModel {
  appService: AppService;
  localStorageService: ILocalStorageService;
  projectId: number;
  actionId: number;
  projectProvider: IProjectsApi;
  orgId: number;
  orgProvider: IOrganisationsApi;
  actionProvider: IActionsApi;
  httpProgress: IHttpProgressModel;
  actionTypeProvider: IActionTypeApi;
  @observable isLoading: boolean = true;
  @observable.ref action: FP.Entities.IAction;
  @observable.ref formModel: SingleFormModel;
  user: FP.Entities.IUser;
  impactProvider: IImpactsApi;
  returnUrl: string;
  tagsProvider: ITagsApi;
  confirmationService: IModalService;
  @observable reviewCommentInput: string;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;

  constructor({ appService, projectId, actionId, user }: IEditActionViewModelOptions) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.actionId = actionId;
    this.user = user;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectTeamUserPermissionsProvider = this.appService.getService<IProjectTeamUserPermissionsApi>(
      Services.ProjectTeamUserPermissionsApi
    );
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.actionTypeProvider = this.appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.confirmationService = appService.getService<IModalService>(Services.ConfirmationService);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.formModel = new SingleFormModel();
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID), 10);

    const queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);

    // check if there is a previous url so it redirects with the previous url in the string params
    const prevUrl = queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");
    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.orgId}/projects/${this.projectId}/actions/${this.actionId}${
        prevUrl ? "?" + QUERY_STRING_PARAMS.RETURN_URL + "=" + encodeURIComponent(prevUrl) : ""
      }`
    );
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
  }

  onMount = async () => {
    await this.loadAction();
    this.formModel.formFields = getActionFormFields(
      this.actionTypeProvider,
      this.projectProvider,
      this.projectTeamUserPermissionsProvider,
      this.projectId,
      this.orgId,
      this.user,
      this.impactProvider,
      [],
      this.tagsProvider,
      this.action
    );

    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto",
          href: this.returnUrl
        }
      },
      {
        id: "UpdateActionButton",
        label: I18n.t("phrases.save"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-2"
        },
        onAction: this.updateAction
      },
      {
        id: "UpdateAndReviewActionButton",
        label: I18n.t("phrases.saveAndReview"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        },
        onAction: this.showReviewModal
      }
    ];
  };

  onUnmount = () => {};

  @action
  loadAction = async () => {
    let res = await this.actionProvider.getDetailedByIdAsync(this.orgId, this.projectId, this.actionId);
    if (!res || res.isError) return;

    runInAction(() => {
      this.action = res.payload;
    });
  };

  @action
  updateAction = async () => {
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.actionProvider.update(
      this.orgId,
      this.projectId,
      this.actionId,
      res as FP.Entities.IAction
    );
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    this.action = result.payload;
    appHistory.push(this.returnUrl);
  };

  updateActionAndMarkAsReviewed() {
    // Set mark as reviewed
    const markAsReviewedField = this.formModel.formFields.find(field => field.key === "markAsReviewed");

    if (markAsReviewedField) {
      markAsReviewedField.value = true;
    }

    // Set the review notes
    const reviewedNotes = this.formModel.formFields.find(field => field.key === "reviewNotes");

    if (reviewedNotes && this.reviewCommentInput) {
      reviewedNotes.value = this.reviewCommentInput;
    }

    // Hide the modal and save the form
    this.confirmationService.hide();
    this.updateAction();
  }

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showReviewModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <SaveAndReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("forms.confirmSaveAndReview"),
        I18n.t("forms.cancelSaveAndReview"),
        ReviewModalComponentProps,
        async () => {
          await this.updateActionAndMarkAsReviewed();
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };
}
