import { AxiosRequestConfig } from "axios";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { FilteredProjectApiModel } from "../../filteredApi/FilteredProjectApiModel";
import { IHttp } from "../../IHttp";
import { IProjectTeamUserPermissionsApi } from "./IProjectTeamUserPermissions.api";

export class ProjectTeamUserPermissionsApi
  extends FilteredProjectApiModel<FP.Entities.IUserPermission>
  implements IProjectTeamUserPermissionsApi
{
  controller: string = "project-team-user-authorisation";
  urlWithOrg: string;
  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "project-team-user-authorisation");
    this.urlWithOrg = `${this.http.url}/${this.version}/organisations`;
  }

  getUsersInProject = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}`;
    let res = await this.http.get(url + query, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getAllUsersSimple = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/project-team-users-simple`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
