import * as React from "react";
import { IFilterModel } from "../../../core/filter/Filter_model";
import { Pill } from "../../ui/Pill";
import { Icon, IconSymbols } from "../../ui/Icon";
import { observer } from "mobx-react-lite";
import { Button, ButtonTypes } from "../../ui/Button";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import I18n from "../../../core/localization/I18n";

export interface FilterPillsProps {
  filterModel: IFilterModel<any>;
}

export const FilterPills: React.FunctionComponent<FilterPillsProps> = observer(props => {
  return (
    <div className="filter-pills">
      <div className="filter-pills__reset-button">
        {props.filterModel.hasStoredFilters && (
          <Button size={UiSizes.XS} type={ButtonTypes.OUTLINE_PRIMARY} className="mr-1" onClick={ee => {
            props.filterModel.resetAllFilters();
          }}>{I18n.t("phrases.resetFilters")}</Button>
        )}
      </div>

      <div className="filter-pills__pill-display">
        {props.filterModel.currentFilters.map((e: any, i: number) => {
          if (!e.value || e.isHidden || e.value.length === 0) {
            return null;
          }

          let filter = props.filterModel.getFilter(e.key) as any;
          let filterList: any[] = filter.value as any;
          if (filterList.length === 0) return null;

          return filterList.map((k: any, j: number) => {
            if (k === "") return null;

            const valueLabel = e.valueRenderer ? e.valueRenderer(k, e) : k;
            const label = e.label ? `${e.label}: ${valueLabel}` : valueLabel;

            return (
              <Pill className="m-1" key={i + "-" + j}>
                <span
                  onClick={ee => {
                    props.filterModel.removeFilterValue(filter.key, k);
                  }}
                >
                  <Icon symbol={IconSymbols.Close} className="mr-2" />
                </span>
                <p className="mb-0 d-inline-block">{label}</p>
              </Pill>
            );
          });
        })}
      </div>

    </div>
  );
});
