import * as React from "react";
import { ITableConfig } from "../../../../../core/table/ITableModel";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { CustomPropertiesModel } from "./CustomProperties_model";
import { Checkbox } from "../../../../../components/ui/Checkbox";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";

interface CheckboxCustomPropertyIsUsedOnAllProps {
  obj: FP.Entities.ICustomProperty;
  onChange: (value: boolean) => Promise<FP.Entities.ICustomProperty>;
}

export const CheckboxCustomPropertyIsUsedOnAll: React.FC<CheckboxCustomPropertyIsUsedOnAllProps> = ({
  obj,
  onChange
}) => {
  let [isChecked, setIsChecked] = React.useState(obj.isRequired);
  return (
    <Checkbox
      className="mr-3"
      checked={isChecked}
      onChange={async e => {
        const s = e.currentTarget.checked;
        setIsChecked(s);
        await onChange(s);
      }}
    />
  );
};

interface CheckboxCustomPropertyIsRequiredProps {
  obj: FP.Entities.ICustomProperty;
  onChange: (value: boolean) => Promise<FP.Entities.ICustomProperty>;
}

export const CheckboxCustomPropertyIsRequired: React.FC<CheckboxCustomPropertyIsRequiredProps> = ({
  obj,
  onChange
}) => {
  let [isChecked, setIsChecked] = React.useState(obj.isRequired);
  return (
    <Checkbox
      className="mr-3"
      checked={isChecked}
      onChange={async e => {
        const s = e.currentTarget.checked;
        setIsChecked(s);
        await onChange(s);
      }}
    />
  );
};

export const GetCustomPropertiesTableConfig = (
  parentModel: CustomPropertiesModel
): ITableConfig<FP.Entities.ICustomProperty> => {
  const canEditOrg = PermissionsContext.canEditOrganisation(parentModel.organisationId);
  return {
    colHeaders: [
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "customEntityType",
        content: <h4 className="mb-0">{I18n.t("table.customPropertyEntityTypeName")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.entityType.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "customPropertyType",
        content: <h4 className="mb-0">{I18n.t("table.customPropertyTypeName")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.propertyType.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    actions: canEditOrg
      ? [
          {
            id: "action1",
            label: I18n.t("phrases.manage"),
            rendersIn: UiActionRenderers.HTML_ANCHOR,
            componentProps: {
              type: ButtonTypes.LINK
            },
            hrefFn: context =>
              `/organisations/${parentModel.organisationId}/settings/customProperties/${context.rowObject.id}`
          },
          {
            id: "delete",
            label: I18n.t("phrases.delete"),
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.LINK
            },
            onAction: (ev, row: ITableRowModel) => {
              parentModel.showConfirmDeleteModal(row.rowObject);
            }
          }
        ]
      : []
  };
};
