import { AppService } from "strikejs-app-service";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { ICustomPropertiesApi } from "../../../../../services/api/v1/customProperties/ICustomPropertiesApi";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getCustomPropertiesManageValueFormFields } from "./ManageValueSettings_fields";



export class ManageValuesSettingsModel extends BaseModel {
  canEditOrganisation: boolean;
  customPropertyServiceProvider: ICustomPropertiesApi;
  formModel: SingleFormModel;
  organisationId: number;


  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    super();
    this.organisationId = organisationId;
    this.customPropertyServiceProvider = appService.getService<ICustomPropertiesApi>(Services.CustomPropertiesApi);
    this.formModel = new SingleFormModel()

  }

  onMount = (canEditOrganisation: boolean) => {
    this.canEditOrganisation = canEditOrganisation;
  }
  onUnmount = () => {

  }

  loadCustomProperty = async (organisationId: number, customPropertyId: number) => {
    const result = await this.customPropertyServiceProvider.getById(organisationId, customPropertyId);
    this.setFormField(result.payload);
    return result.payload;

  }

  setFormField = async (customProperty: FP.Entities.ICustomProperty) => {
    const res = await this.customPropertyServiceProvider.canEditFields(this.organisationId, customProperty.id);
    const fields = getCustomPropertiesManageValueFormFields(this.organisationId, customProperty, res.payload);
    this.formModel.formFields = fields;
    this.formModel.actions = [
      {
        id: "save",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let res = await this.updateCustomProperty();
          if (res) {
            this.formModel.resetFields();
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-auto"
        }
      }
    ];
  }

  updateCustomProperty = () => {
    return null;
  }

}